/* eslint-disable import/no-named-as-default */
/* eslint-disable no-use-before-define */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainWrapper from '../MainWrapper';
import LogIn from '../../Account/LogIn/index';
import Register from '../../Account/Register/index';
import ResetPassword from '../../Account/ResetPassword/index';
import GetOTP from '../../../shared/components/account/login_register/OtpGet';
import ProtectedRoute from '../ProtectedRoute';
import PublicRoute from '../PublicRoute';
import Dashboard from '../../Dashboards/Dashboard';
import AppDashboard from '../../Dashboards/App';
import NotFoundPage from './WrappedRoutes/NotFoundPage';
import UserStores from '../../Dashboards/SendPage';
import UserOrders from '../../Dashboards/Receive';
// import NewContractPage from '../../Dashboards/NewContract';
import Users from '../../Dashboards/AddSafebox';
import ApproversList from '../../Dashboards/ApproversList';
import SafeBoxesOperations from '../../Dashboards/Operations/SafeBoxes';
import ContractOperations from '../../Dashboards/Operations/Contract';
import Tokens from '../../Dashboards/Operations/Tokens';
import Catalog from '../../Dashboards/ECommerce/Catalog';
import Transactions from '../../Dashboards/MetaMask';
import Support from '../../Dashboards/Support';
import Chat from '../../Dashboards/Chat';
import Create from '../../Dashboards/CreateCateogory';
import CustodiyWallet from '../../Dashboards/CustodiyWallet/CustodiyWallet';
import Contracts from '../../Contracts/Contracts';
import OtcMarketplace from '../../Dashboards/OtcMarketplace/OtcMarketplace';
import OtcMarketplaceLists from '../../Dashboards/OtcMarketplace/OtcMarketplaceLists';
import OtcMarketplaceDetail from '../../Dashboards/OtcMarketplace/OtcMarketplaceDetail';
import OtcAccessRequests from '../../Dashboards/OtcMarketplace/OtcAccessRequests';
import AccountRequests from '../../Dashboards/ApproversList/component/AccountsRequests';
import UserAllAccountsList from '../../Dashboards/ApproversList/component/UserAllAccountsList';
import VirtualWallet from '../../Layout/components/VirtualWallet/VirtualWallet';
import AllProducts from '../../Dashboards/AllProducts';
import AllSubAccounts from '../../Dashboards/ApproversList/component/AllSubAccounts';
import TargetFinancial from '../../Dashboards/TargetFinancial/TargetFinancial';
import AllNews from '../../Dashboards/NewsSection/AllNews';
import AllPartners from '../../Dashboards/AddPartnerSection/AllPartners';
import PrivacyNotice from '../../../shared/components/TextEditor/PivacyNotice';
import CookiePolicy from '../../../shared/components/TextEditor/CookiePolicy';
import MigrationTable from '../../Dashboards/MigrationTable';
import WithdrawReport from '../../Dashboards/WithdrawReport';
import MyProfile from '../../Account/MyAccount/MyProfile';
import NotifyUserTable from '../../Dashboards/NotifyUsersTable';
import BalanceReport from '../../Dashboards/AddSafebox/BalanceReport';
import InternalTransferReport from '../../Dashboards/InternalTransferReport';
import ProjectRequests from '../../Dashboards/OtcMarketplace/ProjectRequests';

// import Transactions from '../../Dashboards/Transactions';
// import OrdersList from '../../Dashboards/ECommerce/OrdersList';
// import Payment from '../../Dashboards/ECommerce/Payment';
// import ProductPage from '../../Dashboards/ECommerce/ProductPage';
// import Cart from '../../Dashboards/ECommerce/Cart';
// import ProductsList from '../../Dashboards/ECommerce/ProductsList';
// import ProductEdit from '../../Dashboards/ECommerce/ProductEdit';
// import CreateProduct from '../../../store/Layout/product/CreateProduct';
// import Products from '../../../store/Layout/product/Products';
// import Services from '../../../store/Layout/service/Services';
// import CreateServices from '../../../store/Layout/service/CreateServices';
// import ProductDetails from '../../../store/Layout/product/ProductDetails';
// import ProductCart from '../../../store/Layout/product/ProductCart';
// import Checkout from '../../../store/Layout/product/ProductInvoice';
// import ServiceDetails from '../../../store/Layout/service/ServiceDetails';
// import MarketDashboard from '../../../store/Layout/dashboard/MarketDashboard';
// import Home from '../../../store/Layout/home/Home';
// import HomeProducts from '../../../store/Layout/home/HomeProducts';
// import CreateStore from '../../../store/Layout/createstore/CreateStore';
// import CreateStoreWallet from '../../../store/Layout/createstore/index';
// import StoreDetail from '../../../store/Layout/storedetail/StoreDetail';
// import StoreCard from '../../../store/Layout/storedetail/StoreCard';
// import EditStore from '../../../store/Layout/editstore/EditStore';
// import StoreDetailUser from '../../../store/Layout/storedetail/StoreDetailUser';
// import GetMyOrders from '../../../store/Layout/orders/GetMyOrders';
// import GetMyApproval from '../../../store/Layout/orders/GetMyApproval';
// import GetMyOrderAsBuyer from '../../../store/Layout/orders/GetMyOrderAsBuyer';


const Router = () => {
  const { isLoggedIn } = useSelector(state => state.user);

  React.useEffect(() => {

  }, [isLoggedIn]);

  return (
    <MainWrapper>
      <main>
        <Switch>
          {/* <PublicRoute path="/" isAuthenticated={isLoggedIn}>
            <LogIn />
          </PublicRoute> */}
          <PublicRoute exact path="/" isAuthenticated={isLoggedIn}>
            <LogIn />
          </PublicRoute>
          <PublicRoute path="/login" isAuthenticated={isLoggedIn}>
            <LogIn />
          </PublicRoute>
          <PublicRoute path="/register" isAuthenticated={isLoggedIn}>
            <Register />
          </PublicRoute>
          {/* <PublicRoute path="/Transactions" isAuthenticated={isLoggedIn}>
            <Transactions />
          </PublicRoute> */}
          <PublicRoute path="/reset_password" isAuthenticated={isLoggedIn}>
            <ResetPassword />
          </PublicRoute>
          <PublicRoute path="/Webapistore" isAuthenticated={isLoggedIn}>
            <GetOTP />
          </PublicRoute>

          <ProtectedRoute path="/dashboard" isAuthenticated={isLoggedIn}>
            <Dashboard />
          </ProtectedRoute>
          <ProtectedRoute path="/appdashboard" isAuthenticated={isLoggedIn}>
            <AppDashboard />
          </ProtectedRoute>
          <ProtectedRoute path="/contracts" isAuthenticated={isLoggedIn}>
            <Contracts />
          </ProtectedRoute>

          <ProtectedRoute path="/userstores" isAuthenticated={isLoggedIn}>
            <UserStores />
          </ProtectedRoute>
          <ProtectedRoute path="/userorders" isAuthenticated={isLoggedIn}>
            <UserOrders />
          </ProtectedRoute>
          <ProtectedRoute path="/my-profile" isAuthenticated={isLoggedIn}>
            <MyProfile />
          </ProtectedRoute>
          <ProtectedRoute path="/create" isAuthenticated={isLoggedIn}>
            <Create />
          </ProtectedRoute>
          <ProtectedRoute path="/users" isAuthenticated={isLoggedIn}>
            <Users />
          </ProtectedRoute>
          <ProtectedRoute path="/balance-report/:id" isAuthenticated={isLoggedIn}>
            <BalanceReport />
          </ProtectedRoute>
          <ProtectedRoute path="/withdraw-report" isAuthenticated={isLoggedIn}>
            <WithdrawReport />
          </ProtectedRoute>
          <ProtectedRoute path="/approverslist" isAuthenticated={isLoggedIn}>
            <ApproversList />
          </ProtectedRoute>
          <ProtectedRoute path="/otcmarketplace" isAuthenticated={isLoggedIn}>
            <OtcMarketplace />
          </ProtectedRoute>
          <ProtectedRoute path="/project-requets" isAuthenticated={isLoggedIn}>
            <ProjectRequests />
          </ProtectedRoute>
          <ProtectedRoute path="/accessrequest" isAuthenticated={isLoggedIn}>
            <OtcAccessRequests />
          </ProtectedRoute>
          <ProtectedRoute path="/virtualwallet" isAuthenticated={isLoggedIn}>
            <VirtualWallet />
          </ProtectedRoute>
        
          <ProtectedRoute path="/otcMarketplacedetail" isAuthenticated={isLoggedIn}>
            <OtcMarketplaceDetail />
          </ProtectedRoute>
          <ProtectedRoute path="/otcmarketplacelists" isAuthenticated={isLoggedIn}>
            <OtcMarketplaceLists />
          </ProtectedRoute>
          <ProtectedRoute path="/accountrequests" isAuthenticated={isLoggedIn}>
            <AccountRequests />
          </ProtectedRoute>
          <ProtectedRoute path="/allsubaccounts" isAuthenticated={isLoggedIn}>
            <UserAllAccountsList />
          </ProtectedRoute>
          <ProtectedRoute path="/target-financial" isAuthenticated={isLoggedIn}>
            <TargetFinancial />
          </ProtectedRoute>
          <ProtectedRoute path="/subaccounts" isAuthenticated={isLoggedIn}>
            <AllSubAccounts />
          </ProtectedRoute>
          <ProtectedRoute path="/coinbalance" isAuthenticated={isLoggedIn}>
            <SafeBoxesOperations />
          </ProtectedRoute>
          <ProtectedRoute path="/Support" isAuthenticated={isLoggedIn}>
            <Support />
          </ProtectedRoute>
          <ProtectedRoute path="/privacy-notice" isAuthenticated={isLoggedIn}>
            <PrivacyNotice />
          </ProtectedRoute>
          <ProtectedRoute path="/cookie-policy" isAuthenticated={isLoggedIn}>
            <CookiePolicy />
          </ProtectedRoute>
          <ProtectedRoute path="/migration-report" isAuthenticated={isLoggedIn}>
            <MigrationTable />
          </ProtectedRoute>
          <ProtectedRoute path="/internal-transfer" isAuthenticated={isLoggedIn}>
            <InternalTransferReport />
          </ProtectedRoute>
          <ProtectedRoute path="/notify-users" isAuthenticated={isLoggedIn}>
            <NotifyUserTable />
          </ProtectedRoute>
          <ProtectedRoute path="/CustodiyWallet" isAuthenticated={isLoggedIn}>
            <CustodiyWallet />
          </ProtectedRoute>
          <ProtectedRoute path="/Chat" isAuthenticated={isLoggedIn}>
            <Chat />
          </ProtectedRoute>
          <ProtectedRoute path="/all-products" isAuthenticated={isLoggedIn}>
            <AllProducts />
          </ProtectedRoute>
          <ProtectedRoute path="/fee" isAuthenticated={isLoggedIn}>
            <ContractOperations />
          </ProtectedRoute>
          <ProtectedRoute path="/news" isAuthenticated={isLoggedIn}>
            <AllNews />
          </ProtectedRoute>
          <ProtectedRoute path="/partners" isAuthenticated={isLoggedIn}>
            <AllPartners />
          </ProtectedRoute>
          <ProtectedRoute path="/tokens" isAuthenticated={isLoggedIn}>
            <Tokens />
          </ProtectedRoute>
          <ProtectedRoute path="/transactions" isAuthenticated={isLoggedIn}>
            <Transactions />
          </ProtectedRoute>
          <ProtectedRoute path="/e-commerce/catalog" isAuthenticated={isLoggedIn}>
            <Catalog />
          </ProtectedRoute>
          {/* <ProtectedRoute path="/e-commerce/orders_list" isAuthenticated={isLoggedIn}>
            <OrdersList />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/e-commerce/payment" isAuthenticated={isLoggedIn}>
            <Payment />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/e-commerce/product_page" isAuthenticated={isLoggedIn}>
            <ProductPage />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/e-commerce/cart" isAuthenticated={isLoggedIn}>
            <Cart />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/e-commerce/products_list" isAuthenticated={isLoggedIn}>
            <ProductsList />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/ProductEdit/:id" isAuthenticated={isLoggedIn}>
            <ProductEdit />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/createproduct" isAuthenticated={isLoggedIn}>
            <CreateProduct />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/products" isAuthenticated={isLoggedIn}>
            <Products />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/services" isAuthenticated={isLoggedIn}>
            <Services />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/createservices" isAuthenticated={isLoggedIn}>
            <CreateServices />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/productdetails/:id" isAuthenticated={isLoggedIn}>
            <ProductDetails />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/showcart" isAuthenticated={isLoggedIn}>
            <ProductCart />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/checkout" isAuthenticated={isLoggedIn}>
            <Checkout />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/servicedetails" isAuthenticated={isLoggedIn}>
            <ServiceDetails />
          </ProtectedRoute>  */}
          {/* <ProtectedRoute path="/home" isAuthenticated={isLoggedIn}>
            <Home />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/homeproducts" isAuthenticated={isLoggedIn}>
            <HomeProducts />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/marketdashboard" isAuthenticated={isLoggedIn}>
            <MarketDashboard />
          </ProtectedRoute>  */}
          {/* <ProtectedRoute path="/createstore" isAuthenticated={isLoggedIn}>
            <CreateStoreWallet />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/storecard" isAuthenticated={isLoggedIn}>
            <StoreCard />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/storedetail/:storeId" isAuthenticated={isLoggedIn}>
            <StoreDetail />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/editstore/:storeId" isAuthenticated={isLoggedIn}>
            <EditStore />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/storedetailuser/:storeId" isAuthenticated={isLoggedIn}>
            <StoreDetailUser />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/getmyorders" isAuthenticated={isLoggedIn}>
            <GetMyOrders />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/getmyapproval" isAuthenticated={isLoggedIn}>
            <GetMyApproval />
          </ProtectedRoute> */}
          {/* <ProtectedRoute path="/getmyorderasbuyer" isAuthenticated={isLoggedIn}>
            <GetMyOrderAsBuyer />
          </ProtectedRoute> */}
          {/* <Route exact path="/" component={LogIn} />
        <Route path="/login" component={LogIn} />
        <Route path="/register" component={Register} />
        <Route path="/reset_password" component={ResetPassword} />
        <Route path="/Webapistore" component={GetOTP} /> */}
          {/* <ProtectedRoute exact path="/" component={WrappedRoutes} /> */}
          {/* <Route path="/" component={WrappedRoutes} /> */}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </main>
    </MainWrapper>
  );
};
export default Router;
