import moment from "moment";
import { useEffect, useState } from "react";

import { getAllOtcMarketPlace } from "../../../utils/services/user.service";
import "../ApproversList/table.css";
import "react-toastify/dist/ReactToastify.css";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CreateOtcMarketplaceModal from "./CreateOtcMarketplaceModal";
import DeleteOtcMarketplace from "./DeleteOtcMarketplace";
import UpdateOtcMarketplaceModal from "./UpdateOtcMarketplaceModal";
import { Link } from "react-router-dom";
import { Ring } from "@uiball/loaders";
import TitleCard from "../AddSafebox/TitleCard";
import CreateOtcMarketplaceGeneralModal from "./CreateOtcMarketplaceGeneralModal";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";

function OtcMarketplaceLists() {
  const { t } = useTranslation("common");

  const itemsPerPages = 20;

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [pageCount, setPageCount] = useState(1); // Initialize with 1 page.

  const [show, setShow] = useState(false);
  const [showGeneral, setShowGeneral] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [otcMarketplaceLists, setOtcMarketplaceLists] = useState([]);
  console.log(
    "🚀 ~ OtcMarketplaceLists ~ otcMarketplaceLists:",
    otcMarketplaceLists
  );
  const [reFetch, setReFetch] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null); // State to track the id of the item to delete

  useEffect(() => {
    const getAllOtcMarketLists = async () => {
      try {
        setIsLoading(true);
        const payload = {
          limit: itemsPerPages,
          page_number: currentPage,
        };
        const response = await getAllOtcMarketPlace(payload);
        setIsLoading(false);
        if (response) {
          setOtcMarketplaceLists(response?.data?.response);
          const totalUsers = response.data.count;
          const pages = Math.ceil(totalUsers / itemsPerPages);
          setPageCount(pages);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    getAllOtcMarketLists();
  }, [currentPage, reFetch]);

  const handleDeleteClick = (itemId) => {
    setDeleteItemId(itemId);
    setShowDeleteModal(true);
  };
  const getStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return (
          <div className="mt-2 bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2 py-0.5 rounded">
            Pending
          </div>
        );
      case "active":
        return (
          <div className="mt-2 bg-green-100 text-green-800 text-sm font-medium mr-2 px-1 py-0.5 rounded">
            Approved
          </div>
        );
      case "rejected":
        return (
          <div className="mt-2 bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-0.5 rounded ">
            Rejected
          </div>
        );
      case "deactivated":
        return (
          <div className="mt-2 bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-1 py-0.5 rounded">
            Deactivated
          </div>
        );
      default:
        return <div className="badge">Unknown</div>;
    }
  };
  return (
    <>
      <Container>
        <TitleCard title="" topMargin="mt-2">
          <div
            className="loader-overlay"
            style={{
              display: isLoading ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(255, 255, 255, 0.8)",
              zIndex: 999,
            }}
          >
            <Ring size={65} color="#00897B" />
          </div>

          <h3 className="mb-4">OTC Marketplace Lists</h3>
          <div className="d-flex flex-row gap-2 py-2 ">
            {/* <button
            className="text-white p-1 py-2 rounded-md bg-primary "
            onClick={() => setShow(true)}
          >
            Create OTC Marketplace
            
          </button> */}
           <div className="flex flex-col md:flex-row">
           <FormButton
              variant="outline-primary"
              type="submit"
              onClick={() => setShow(true)}
            >
              Create OTC Marketplace
            </FormButton>
            <FormButton
              variant="outline-primary"
              type="submit"
              onClick={() => setShowGeneral(true)}
            >
              Create OTC Marketplace General
            </FormButton></div>
            {/* <button
              className="text-white p-1 py-2 rounded-md bg-primary "
              onClick={() => setShowGeneral(true)}
            >
              Create OTC Marketplace General
            </button> */}
          </div>
          <div className="overflow-x-auto w-full">
            <table className="tbody table w-full ">
              <thead>
                <tr>
                  <th>Marketplace Id</th>
                  <th>Marketplace Name</th>
                  <th>Marketplace Status</th>
                  <th>Marketplace Type</th>
                  <th>Buy package limit </th>
                  <th>More Details</th>
                  <th>Delete Marketplace</th>
                </tr>
              </thead>
              <tbody>
                {otcMarketplaceLists?.length > 0 ? (
                  otcMarketplaceLists.map((store) => (
                    <tr key={store._id}>
                      <td>
                        <div className="flex items-center space-x-3">
                          <div>
                            <div className="">{store._id}</div>
                          </div>
                        </div>
                      </td>

                      <td className="text-start">{store?.market_place_name}</td>

                      <td>
                        <div className="relative inline-block text-black">
                          {getStatusBadge(store.status)}
                        </div>
                      </td>
                      <td className="text-start">{store?.otc_type}</td>

                      <td className="text-start">
                        {store?.buy_package_user_limit || "--"}
                      </td>

                      <td className="flex justify-center items-center">
                        <Link to={`/otcmarketplacedetail?id=${store._id}`}>
                          <button className="text-lime-500 hover:underline mt-0.5 mr-16">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          </button>
                        </Link>
                      </td>
                      <td className="text-start">
                        <button
                          className="text-lime-500 bg-danger px-2 rounded-md hover:underline "
                          onClick={() => handleDeleteClick(store._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No Marketplace Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {pageCount > 1 && (
            <div className="flex items-center justify-center mt-4">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                className="flex items-center justify-center px-1 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="w-2.5 h-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
              </button>
              {/* Pagination links */}
              {pageCount <= 3 ? (
                Array.from({ length: pageCount }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index + 1)}
                    className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                      currentPage === index + 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                  >
                    {index + 1}
                  </button>
                ))
              ) : (
                <>
                  {currentPage > 1 && (
                    <>
                      <button
                        onClick={() => setCurrentPage(1)}
                        className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${
                          currentPage === 1
                            ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                            : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        }`}
                      >
                        1
                      </button>
                      {currentPage > 3 && <span className="dots">....</span>}
                    </>
                  )}
                  {currentPage > 2 && (
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${
                        currentPage === currentPage - 1
                          ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                    >
                      {currentPage - 1}
                    </button>
                  )}
                  <button
                    className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${
                      currentPage === currentPage
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                  >
                    {currentPage}
                  </button>
                  {currentPage < pageCount - 1 && (
                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${
                        currentPage === currentPage + 1
                          ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                    >
                      {currentPage + 1}
                    </button>
                  )}
                  {currentPage < pageCount && (
                    <>
                      {currentPage < pageCount - 2 && (
                        <span className="dots">....</span>
                      )}
                      <button
                        onClick={() => setCurrentPage(pageCount)}
                        className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${
                          currentPage === pageCount
                            ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                            : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        }`}
                      >
                        {pageCount}
                      </button>
                    </>
                  )}
                </>
              )}
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === pageCount}
                className="flex items-center justify-center px-1 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <span className="sr-only">Next</span>
                <svg
                  class="w-1.5 h-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </div>
          )}
        </TitleCard>
        {show && (
          <CreateOtcMarketplaceModal
            show={show}
            hide={() => setShow(false)}
            reFetch={() => setReFetch(!reFetch)}
          />
        )}
        {showGeneral && (
          <CreateOtcMarketplaceGeneralModal
            show={showGeneral}
            hide={() => setShowGeneral(false)}
            reFetch={() => setReFetch(!reFetch)}
          />
        )}
      </Container>

      {deleteItemId && ( // Render delete modal only if deleteItemId is set
        <DeleteOtcMarketplace
          show={showDeleteModal}
          hide={() => setShowDeleteModal(false)}
          reFetch={() => setReFetch(!reFetch)}
          marketplaceId={deleteItemId} // Pass the deleteItemId as a prop
        />
      )}
    </>
  );
}

export default OtcMarketplaceLists;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
`;
