/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Alert } from 'react-bootstrap';
import { MdOutlineNumbers } from 'react-icons/md';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';


import FormField from '../../../../../shared/components/form/FormField';
import { AccountButton } from '../../../../../shared/components/account/AccountElements';

const ApproveOtpForm = ({
  onSubmit, fieldUser, errorMsg, onClose, loading, status,
}) => (
  <Form
    onSubmit={onSubmit} 
    validate={(values) => {
    const errors = {};
      const otpRegex = /^[0-9\b]+$/;
      if (!values.otp) {
        errors.otp = 'Otp is Required!!';
      } else if (!otpRegex.test(values.otp)) {
        errors.otp = 'Invalid Otp!! must be digits';
      }
    return errors;
  }}
  >
    {({ handleSubmit }) => (
      <FormContainer onSubmit={handleSubmit}>
        <Alert
          variant={status === 200 ? 'success' : 'danger'}
          show={errorMsg !== undefined}
          style={{
   marginTop: '1rem',
width: '100%',
textAlign: 'center',
  backgroundColor: status === 200 ? '#c1f9c1' : '#f1beb0',
color: status === 200 ? '#22bb33' : 'red', 
}}
          onClose={onClose}
          dismissible
        >
          {errorMsg}
        </Alert>
        <FormGroup>
          <div>
            <FormGroupLabel>{fieldUser}</FormGroupLabel>
          </div>
          <FormGroupField>
            <FormGroupIcon>
              <MdOutlineNumbers />
            </FormGroupIcon>
            <Field
              name="otp"
              id="email"
              component={FormField}
              type="text"
              placeholder="Enter OTP"
              className="input-without-border-radius"
            />
          </FormGroupField>
        </FormGroup>
        <AccountButton variant={loading ? 'secondary' : 'primary'} type="submit">
          {loading ? 'Loading...' : 'Send Otp'}
        </AccountButton>
      </FormContainer>
    )}
  </Form>
);

ApproveOtpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldUser: PropTypes.shape(),
};

ApproveOtpForm.defaultProps = {
  fieldUser: null,
};

export default ApproveOtpForm;
