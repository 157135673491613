/* eslint-disable max-len */

import axios from "axios";
import authHeader from "./auth-header";
import { toast } from "react-toastify";
export const API_URL = "https://dev.custodiy.com/api/";
// export const API_URL = 'https://admin.custodiy.com/api/';
export const API_URL_BLANK = "https://web.custodiy.com/";

// export const API_URL = process.env.REACT_APP_API_URL;
// console.log('encd', process.env.REACT_APP_API_URL, API_URL);
const user = JSON.parse(sessionStorage.getItem("user"));
const axiosInstance = axios.create({
  baseURL: API_URL,
});

export const logout_user = async (data) => {
  return axiosInstance
    .post(`${API_URL}logout_user`, data)
    .then((response) => {
      // Handle the response as needed
      return response.data;
    })
    .catch((error) => {
      // Handle the error as needed
      console.error("Logout error:", error);
      throw error;
    });
};
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.Message) {
      const errorMessage = error.response.data.Message;
      if (errorMessage === "Token expired") {
        const user = JSON.parse(sessionStorage.getItem("user"));
        console.log("🚀 ~ user:--------", user);
        logout_user({ user_id: user?.data?.user.user_id });
        sessionStorage.removeItem("user");
        window.location.href = "/";
      }
    }

    return Promise.reject(error);
  }
);
export const getTotalBalance = () =>
  axiosInstance.get(`${API_URL}total_usd_balance`, { headers: authHeader() });
export const getUserTotalBalance = () =>
  axiosInstance.get(`${API_URL}total_usd_balance`, { headers: authHeader() });
export const getSendAmount = () =>
  axiosInstance.get(`${API_URL}sentAmount`, { headers: authHeader() });
export const getReceiveAmount = () =>
  axiosInstance.get(`${API_URL}receivedAmount`, { headers: authHeader() });
export const getContractAmount = () =>
  axiosInstance.get(`${API_URL}contractAmount`, { headers: authHeader() });
export const getContracts = () =>
  axiosInstance.post(
    `${API_URL}getContracts`,
    { email: user.data.user.email, limit: 100, skip: 0 },
    { headers: authHeader() }
  );
// eslint-disable-next-line max-len
export const getSafebox = () =>
  axiosInstance.post(
    `${API_URL}getSafebox`,
    { limit: 100, skip: 0 },
    { headers: authHeader() }
  );
export const getApprovers = () =>
  axiosInstance.get(`${API_URL}users`, { headers: authHeader() });
export const addSafebox = () =>
  axiosInstance.post(`${API_URL}safebox`, "", { headers: authHeader() });
export const getBeneficiaryName = (name) =>
  axiosInstance.post(`${API_URL}search_name`, name, { headers: authHeader() });
export const getUsdFee = () =>
  axiosInstance.get(`${API_URL}USDC_price`, { headers: authHeader() });
export const getUsdtFee = () =>
  axiosInstance.get(`${API_URL}USDT_price`, { headers: authHeader() });
export const getEthFee = () =>
  axiosInstance.get(`${API_URL}eth_price`, { headers: authHeader() });
export const getEurFee = () =>
  axiosInstance.get(`${API_URL}euroc_price`, { headers: authHeader() });
export const getGbpFee = () =>
  axiosInstance.get(`${API_URL}gbpt_price`, { headers: authHeader() });
export const getJypFee = () =>
  axiosInstance.get(`${API_URL}jpyc_price`, { headers: authHeader() });
export const getMatFee = () =>
  axiosInstance.get(`${API_URL}matic_price`, { headers: authHeader() });
export const getCtyFee = () =>
  axiosInstance.get(`${API_URL}cty_price`, { headers: authHeader() });
export const getPaxFee = () =>
  axiosInstance.get(`${API_URL}PAX_price`, { headers: authHeader() });
export const getBusdFee = () =>
  axiosInstance.get(`${API_URL}BUSD_price`, { headers: authHeader() });
export const getBnbFee = () =>
  axiosInstance.get(`${API_URL}bnb_price`, { headers: authHeader() });
export const getBTCFee = () =>
  axiosInstance.get(`${API_URL}BTC_price`, { headers: authHeader() });
export const getSafeboxDetail = (id) =>
  axiosInstance.get(`${API_URL}safebox/${id}`, { headers: authHeader() });
export const getContractDetail = (id) =>
  axiosInstance.get(`${API_URL}getTransactionDetail/${id}`, {
    headers: authHeader(),
  });
export const SendTwoFactor = () =>
  axiosInstance.post(`${API_URL}change_2fa_status`, "", {
    headers: authHeader(),
  });
export const approveContractOtp = () =>
  axiosInstance.post(`${API_URL}approve-contract-otp`, "", {
    headers: authHeader(),
  });
export const transferInternalBalance = (Data) =>
  axiosInstance.post(`${API_URL}transferInternalBalance`, Data, {
    headers: authHeader(),
  });
export const getUserBalanceInternalWallet = (userId) =>
  axiosInstance.get(`${API_URL}userBalanceInternalWallet`, {
    headers: authHeader(),
    params: {
      userId: userId, // or just userId if using ES6 shorthand
    },
  });

export const approveExternalWalletOtp = (otp) =>
  axiosInstance.post(
    `${API_URL}verifyOtpExternalWallet`,
    { otp },
    { headers: authHeader() }
  );
export const approveSenExternalWalletOtp = (otp) =>
  axiosInstance.post(
    `${API_URL}sendTokenExternalGasCalculation`,
    { otp },
    { headers: authHeader() }
  );
export const getUserInfo = () =>
  axiosInstance.get(`${API_URL}user_info`, { headers: authHeader() });
export const safeboxApprove = (id) =>
  axiosInstance.post(
    `${API_URL}safebox-approve`,
    { id },
    { headers: authHeader() }
  );

export const safeboxReject = (id) =>
  axiosInstance.post(
    `${API_URL}safebox-reject`,
    { id },
    { headers: authHeader() }
  );
export const calculateAdminCommissions = (commissionVals) =>
  axiosInstance.post(`${API_URL}calculateAdminCommissions`, commissionVals, {
    headers: authHeader(),
  });
export const getIpfs = (ipfs) =>
  axiosInstance.post(`${API_URL}uploadFileIpfs`, ipfs, {
    headers: authHeader(),
  });

// Market place routes
export const createMarketPlaceInternal = (formData) =>
  axiosInstance.post(`${API_URL}createMarketPlaceInternal`, formData, {
    headers: authHeader(),
  });
export const createMarketPlaceExternal = (formData) =>
  axiosInstance.post(`${API_URL}createMarketPlaceExternal`, formData, {
    headers: authHeader(),
  });
export const createProduct = (formData) =>
  axiosInstance.post(`${API_URL}uploadProduct`, formData, {
    headers: authHeader(),
  });
export const updateProduct = (formData) =>
  axiosInstance.post(`${API_URL}updateProduct`, formData, {
    headers: authHeader(),
  });
export const CreateOrder = (formData) =>
  axiosInstance.post(`${API_URL}CreateOrder`, formData, {
    headers: authHeader(),
  });
export const CreateOrderInternal = (formData) =>
  axiosInstance.post(`${API_URL}CreateOrderInternal`, formData, {
    headers: authHeader(),
  });
export const transferStoreFee = (Data) =>
  axiosInstance.post(`${API_URL}transferStoreCreation_fee`, Data, {
    headers: authHeader(),
  });
export const checkStore = () =>
  axiosInstance.get(`${API_URL}checkIsStoreExists`, { headers: authHeader() });
export const getCategory = () =>
  axiosInstance.get(`${API_URL}getCategories`, { headers: authHeader() });
export const getMyStore = () =>
  axiosInstance.get(`${API_URL}getMy_store`, { headers: authHeader() });
export const getMyProduct = (formData) =>
  axiosInstance.post(`${API_URL}getMy_products`, formData, {
    headers: authHeader(),
  });
export const getProductData = (productId) =>
  axiosInstance.post(`${API_URL}getProductInformation`, productId, {
    headers: authHeader(),
  });
export const byPassFee = (data) =>
  axiosInstance.post(`${API_URL}byPassFee`, data, {
    headers: authHeader(),
  });
export const getInternalTransferHistory = (data) =>
  axiosInstance.post(`${API_URL}getInternalTransferHistory`, data, {
    headers: authHeader(),
  });
export const getApprover = () =>
  axiosInstance.get(`${API_URL}users`, { headers: authHeader() });
export const getProductReview = (productId) =>
  axiosInstance.post(`${API_URL}getProductReviews`, productId, {
    headers: authHeader(),
  });
export const getSingleProductDetail = (name) =>
  axiosInstance.post(`${API_URL}searchProduct`, name, {
    headers: authHeader(),
  });
export const getAllStore = (data) =>
  axiosInstance.post(`${API_URL}getAllStore`, data, { headers: authHeader() });
export const searchAllStore = (data) =>
  axiosInstance.post(`${API_URL}searchProductByNameOrSubCategory`, data, {
    headers: authHeader(),
  });
export const editMyStore = (data) =>
  axiosInstance.post(`${API_URL}editMyStoreDetails`, data, {
    headers: authHeader(),
  });
export const editStoreLogo = (data) =>
  axiosInstance.post(`${API_URL}changeStoreLogo`, data, {
    headers: authHeader(),
  });
export const getMyOrders = (data) =>
  axiosInstance.post(`${API_URL}getMyOrders`, data, { headers: authHeader() });
export const getMyApproval = (data) =>
  axiosInstance.post(`${API_URL}getOrderForMyApproval`, data, {
    headers: authHeader(),
  });
export const rejectOrder = (data) =>
  axiosInstance.post(`${API_URL}rejectOrder`, data, { headers: authHeader() });
export const approveOrder = (data) =>
  axiosInstance.post(`${API_URL}approveOrder`, data, { headers: authHeader() });
export const getMyOrdersAsBuyer = (data) =>
  axiosInstance.post(`${API_URL}getMyOrdersAsBuyer`, data, {
    headers: authHeader(),
  });
export const submitRatting = (data) =>
  axiosInstance.post(`${API_URL}submitRatting`, data, {
    headers: authHeader(),
  });
export const updateOrderStatusSeller = (data) =>
  axiosInstance.post(`${API_URL}updateOrderStatusSeller`, data, {
    headers: authHeader(),
  });
export const getGlobalProducts = (data) =>
  axiosInstance.post(`${API_URL}getGlobalProducts`, data, {
    headers: authHeader(),
  });
export const searchStoretByNameOrCategory = (data) =>
  axiosInstance.post(`${API_URL}searchStoretByNameOrCategory`, data, {
    headers: authHeader(),
  });

// AdminDashBoard routes
export const login = (data) =>
  axiosInstance.post(`${API_URL}login`, data, { headers: authHeader() });
export const getUserStores = (data) =>
  axiosInstance.post(`${API_URL}getUserStores`, data, {
    headers: authHeader(),
  });
export const updateStoreStatus = (data) =>
  axiosInstance.post(`${API_URL}updateStoreStatus`, data, {
    headers: authHeader(),
  });
export const rejectPendingStore_admin = (data) =>
  axiosInstance.post(`${API_URL}rejectPendingStore_admin`, data, {
    headers: authHeader(),
  });
export const getUser = (data) =>
  axiosInstance.post(`${API_URL}getUser`, data, { headers: authHeader() });
export const userFilter = (data) =>
  axiosInstance.post(`${API_URL}userFilter`, data, { headers: authHeader() });
export const getUserOrder = (data) =>
  axiosInstance.post(`${API_URL}getUserOrder`, data, { headers: authHeader() });
export const newOrderNewOrderGraph = () =>
  axiosInstance.get(`${API_URL}newOrderNewOrderGraph`, {
    headers: authHeader(),
  });
export const userAndSaleDetails = () =>
  axiosInstance.get(`${API_URL}userAndSaleDetails`, { headers: authHeader() });

export const approvedPendingStore_admin = (data) =>
  axiosInstance.post(`${API_URL}approvedPendingStore_admin`, data, {
    headers: authHeader(),
  });
export const getCategory_admin = (data) =>
  axiosInstance.post(`${API_URL}getCategories_admin`, data, {
    headers: authHeader(),
  });
export const resetPasswordAdmin = (data) =>
  axiosInstance.post(`${API_URL}resetPasswordAdmin`, data, {
    headers: authHeader(),
  });
export const onOffUserTwoFactor = (data) =>
  axiosInstance.post(`${API_URL}onOffUserTwoFactor`, data, {
    headers: authHeader(),
  });
export const verifyUser = (data) =>
  axiosInstance.post(`${API_URL}verifyUser`, data, { headers: authHeader() });
export const addNewCategory_admin = (data) =>
  axiosInstance.post(`${API_URL}addNewCategory_admin`, data, {
    headers: authHeader(),
  });
export const deactivaCategory = (data) =>
  axiosInstance.post(`${API_URL}deactivaCategory`, data, {
    headers: authHeader(),
  });
export const changeStoreCategory = (data) =>
  axiosInstance.post(`${API_URL}changeStoreCategory`, data, {
    headers: authHeader(),
  });
export const getTickets = (data) =>
  axiosInstance.post(`${API_URL}getTickets`, data, { headers: authHeader() });
export const getTicketMessages = (data) =>
  axiosInstance.post(`${API_URL}getTicketMessages`, data, {
    headers: authHeader(),
  });
export const ticketReply = (data) =>
  axiosInstance.post(`${API_URL}ticketReply`, data, { headers: authHeader() });
export const updateTicketStatus = (data) =>
  axiosInstance.post(`${API_URL}updateTicketStatus`, data, {
    headers: authHeader(),
  });
export const searchTicket = (data) =>
  axiosInstance.post(`${API_URL}searchTicket`, data, { headers: authHeader() });
export const getNotification = (data) =>
  axiosInstance.post(`${API_URL}getNotification`, data, {
    headers: authHeader(),
  });
export const markAllAsRead = (data) =>
  axiosInstance.post(`${API_URL}markAllAsRead`, data, {
    headers: authHeader(),
  });
export const storeDetails = () =>
  axiosInstance.get(`${API_URL}storeDetails`, { headers: authHeader() });
export const productDetails = () =>
  axiosInstance.get(`${API_URL}productDetails`, { headers: authHeader() });
export const orderDetails = () =>
  axiosInstance.get(`${API_URL}orderDetails`, { headers: authHeader() });
export const getRecentOrder = () =>
  axiosInstance.get(`${API_URL}getRecentOrder`, { headers: authHeader() });
export const adminSwitchProfile = (data) =>
  axiosInstance.post(`${API_URL}adminSwitchProfile`, data, {
    headers: authHeader(),
  });
export const getSaleReport = () =>
  axiosInstance.get(`${API_URL}getSaleReport`, { headers: authHeader() });
export const getStoreReport = () =>
  axiosInstance.get(`${API_URL}getStoreReport`, { headers: authHeader() });
export const getSaleGraph = () =>
  axiosInstance.get(`${API_URL}getSaleGraph`, { headers: authHeader() });
export const getTopSellingProducts = () =>
  axiosInstance.get(`${API_URL}getTopSellingProducts`, {
    headers: authHeader(),
  });
export const userDetails = () =>
  axiosInstance.get(`${API_URL}userDetails`, { headers: authHeader() });
export const userGraph = () =>
  axiosInstance.get(`${API_URL}userGraph`, { headers: authHeader() });
export const recentUser = () =>
  axiosInstance.get(`${API_URL}recentUser`, { headers: authHeader() });
export const getFeeGraph = () =>
  axiosInstance.get(`${API_URL}getFeeGraph`, { headers: authHeader() });
export const listFee = () =>
  axiosInstance.get(`${API_URL}listFee `, { headers: authHeader() });
export const getTransactionDetails = (data) =>
  axiosInstance.post(`${API_URL}getTransactionDetails`, data, {
    headers: authHeader(),
  });
export const uploadProfile = (formData) =>
  axiosInstance.post(`${API_URL}uploadProfile`, formData, {
    headers: authHeader(),
  });
export const getChat = (data) =>
  axiosInstance.post(`${API_URL}getchat`, data, { headers: authHeader() });
export const getChatMessages = (data) =>
  axiosInstance.post(`${API_URL}getChatMessages`, data, {
    headers: authHeader(),
  });
export const editFee = (data) =>
  axiosInstance.post(`${API_URL}editFee`, data, { headers: authHeader() });
export const addFee = (data) =>
  axiosInstance.post(`${API_URL}addFee `, data, { headers: authHeader() });
export const userBalance = (data) =>
  axiosInstance.post(`${API_URL}userBalance  `, data, {
    headers: authHeader(),
  });
export const getUserInternalWalletBalance = (data) =>
  axiosInstance.post(`${API_URL}getUserInternalWalletBalance  `, data, {
    headers: authHeader(),
  });
export const editWallet = (data) =>
  axiosInstance.post(`${API_URL}editWallet  `, data, { headers: authHeader() });
export const addNewWallet = (data) =>
  axiosInstance.post(`${API_URL}addNewWallet  `, data, {
    headers: authHeader(),
  });
export const editCategory_admin = (data) =>
  axiosInstance.post(`${API_URL}editCategory_admin  `, data, {
    headers: authHeader(),
  });
export const varifyOtp_wallet = (data) =>
  axiosInstance.post(`${API_URL}varifyOtp_wallet  `, data, {
    headers: authHeader(),
  });
export const rejectUser = (data) =>
  axiosInstance.post(`${API_URL}rejectUser  `, data, { headers: authHeader() });
export const approveUser = (data) =>
  axiosInstance.post(`${API_URL}approveUser  `, data, {
    headers: authHeader(),
  });
export const getApproverss = (data) =>
  axiosInstance.post(`${API_URL}getApprovers  `, data, {
    headers: authHeader(),
  });
export const updateUserStatus = (data) =>
  axiosInstance.post(`${API_URL}updateUserStatus  `, data, {
    headers: authHeader(),
  });
export const getWallet = () =>
  axiosInstance.get(`${API_URL}getWallet `, { headers: authHeader() });
export const sendOtp = () =>
  axiosInstance.get(`${API_URL}sendOtp `, { headers: authHeader() });
export const verifyTFA = (formData) =>
  axiosInstance.post(`${API_URL}verifyTFA`, formData, {
    headers: authHeader(),
  });
export const verifyTFAEmail = (formData) =>
  axiosInstance.post(`${API_URL}verifyTFAEmail`, formData, {
    headers: authHeader(),
  });
export const tfaEnable = () =>
  axiosInstance.get(`${API_URL}TFAEnable`, { headers: authHeader() });
export const tfaDisable = () =>
  axiosInstance.get(`${API_URL}TFADisable`, { headers: authHeader() });
export const create = () =>
  axiosInstance.get(`${API_URL}create`, { headers: authHeader() });
export const getContractList = () =>
  axiosInstance.get(`${API_URL}getContractList`, { headers: authHeader() });
export const getToken = (formData) =>
  axiosInstance.post(`${API_URL}getToken`, formData, { headers: authHeader() });
export const addNewToken = (formData) =>
  axiosInstance.post(`${API_URL}addNewToken`, formData, {
    headers: authHeader(),
  });
export const editToken = (formData) =>
  axiosInstance.post(`${API_URL}editToken`, formData, {
    headers: authHeader(),
  });
export const deleteToken = (formData) =>
  axiosInstance.post(`${API_URL}deleteToken`, formData, {
    headers: authHeader(),
  });
export const getUserInfo1 = (formData) =>
  axiosInstance.post(`${API_URL}user_infoEmail`, formData, {
    headers: authHeader(),
  });
export const getUserWalletsAndEmail = (formData) =>
  axiosInstance.post(`${API_URL}getUserWalletsAndEmail`, formData, {
    headers: authHeader(),
  });

export const login1 = (formData) =>
  axiosInstance.post(`${API_URL}login`, formData, { headers: authHeader() });
export const verifyTwoFactor1 = (formData) =>
  axiosInstance.post(`${API_URL}varify_2fa_otp`, formData, {
    headers: authHeader(),
  });
export const sendOtpGeneral = (formData) =>
  axiosInstance.post(`${API_URL}sendOtpGeneral`, formData, {
    headers: authHeader(),
  });
export const resendOtpGeneral = (formData) =>
  axiosInstance.post(`${API_URL}resendOtpGeneral`, formData, {
    headers: authHeader(),
  });
export const varifyOtpGeneral = (formData) =>
  axiosInstance.post(`${API_URL}varifyOtpGeneral`, formData, {
    headers: authHeader(),
  });
export const userChatStatus = (formData) =>
  axiosInstance.post(`${API_URL}userChatStatus`, formData, {
    headers: authHeader(),
  });
export const blockUnblockUser = (formData) =>
  axiosInstance.post(`${API_URL}blockUnblockUser`, formData, {
    headers: authHeader(),
  });
export const changeMarketPlaceOwnerShip = (formData) =>
  axiosInstance.post(`${API_URL}changeMarketPlaceOwnerShip`, formData, {
    headers: authHeader(),
  });
export const mainContractChangeOwnerShip = (formData) =>
  axiosInstance.post(`${API_URL}mainContractChangeOwnerShip`, formData, {
    headers: authHeader(),
  });
export const escrowContractChangeOwnerShip = (formData) =>
  axiosInstance.post(`${API_URL}escrowContractChangeOwnerShip`, formData, {
    headers: authHeader(),
  });
export const getOtcMarketplaceRequest = (formData) =>
  axiosInstance.post(`${API_URL}getOtcMarketplaceRequest`, formData, {
    headers: authHeader(),
  });
export const getProjectList = (formData) =>
  axiosInstance.post(`${API_URL}getProjectList`, formData, {
    headers: authHeader(),
  });
export const updateOtcMarketplaceRequest = (formData) =>
  axiosInstance.post(`${API_URL}updateOtcMarketplaceRequest`, formData, {
    headers: authHeader(),
  });
export const updateStatus = (formData) =>
  axiosInstance.post(`${API_URL}updateStatus`, formData, {
    headers: authHeader(),
  });
export const getAllOtcMarketPlace = (formData) =>
  axiosInstance.post(`${API_URL}getAllOtcMarketPlace`, formData, {
    headers: authHeader(),
  });
export const createOtcMarketPlace = (formData) =>
  axiosInstance.post(`${API_URL}createOtcMarketPlace`, formData, {
    headers: authHeader(),
  });
export const create_otcMarketPlace_general = (formData) =>
  axiosInstance.post(`${API_URL}create_otcMarketPlace_general`, formData, {
    headers: authHeader(),
  });
export const deleteOtcMarketPlace = (formData) =>
  axiosInstance.post(`${API_URL}deleteOtcMarketPlace`, formData, {
    headers: authHeader(),
  });
export const updateOtcMarketPlace = (formData) =>
  axiosInstance.post(`${API_URL}updateOtcMarketPlace`, formData, {
    headers: authHeader(),
  });
export const getMarketPlaceDetailsById = (formData) =>
  axiosInstance.post(`${API_URL}getMarketPlaceDetailsById`, formData, {
    headers: authHeader(),
  });
export const pauseOtcMarketPlace = (formData) =>
  axiosInstance.post(`${API_URL}pauseOtcMarketPlace`, formData, {
    headers: authHeader(),
  });
export const playOtcMarketPlace = (formData) =>
  axiosInstance.post(`${API_URL}playOtcMarketPlace`, formData, {
    headers: authHeader(),
  });
export const addOtcMarketPlacePackage = (formData) =>
  axiosInstance.post(`${API_URL}addOtcMarketPlacePackage`, formData, {
    headers: authHeader(),
  });
export const getOtcMarketPlacePackage = (formData) =>
  axiosInstance.post(`${API_URL}getOtcMarketPlacePackage`, formData, {
    headers: authHeader(),
  });
export const editOtcMarketPlacePackage = (formData) =>
  axiosInstance.post(`${API_URL}editOtcMarketPlacePackage`, formData, {
    headers: authHeader(),
  });
export const deleteOtcMarketPlacePackage = (formData) =>
  axiosInstance.post(`${API_URL}deleteOtcMarketPlacePackage`, formData, {
    headers: authHeader(),
  });
export const getAccessRequest = (formData) =>
  axiosInstance.post(`${API_URL}getAccessRequest`, formData, {
    headers: authHeader(),
  });
export const updateAccessRequest = (formData) =>
  axiosInstance.post(`${API_URL}updateAccessRequest`, formData, {
    headers: authHeader(),
  });
export const getOtcPackageTrx = (formData) =>
  axiosInstance.post(`${API_URL}getOtcPackageTrx`, formData, {
    headers: authHeader(),
  });
export const getAllTokens = (formData) =>
  axiosInstance.post(`${API_URL}getToken`, formData, {
    headers: authHeader(),
  });
export const getWithdrawRequest = (formData) =>
  axiosInstance.post(`${API_URL}getWithdrawRequest`, formData, {
    headers: authHeader(),
  });
export const rejectWithdraw = (formData) =>
  axiosInstance.post(`${API_URL}rejectWithdraw`, formData, {
    headers: authHeader(),
  });
export const approveWithdraw = (formData) =>
  axiosInstance.post(`${API_URL}approveWithdraw`, formData, {
    headers: authHeader(),
  });
export const manulWithdrawRequestUpdate = (formData) =>
  axiosInstance.post(`${API_URL}manulWithdrawRequestUpdate`, formData, {
    headers: authHeader(),
  });
export const getPoolBalance = (data) =>
  axiosInstance.post(`${API_URL}getPoolBalance`, data, {
    headers: authHeader(),
  });
export const getUserPendingBalance = (data) =>
  axiosInstance.post(`${API_URL}getUserPendingBalance`, data, {
    headers: authHeader(),
  });
export const transferGasBNB = (data) =>
  axiosInstance.post(`${API_URL}transferGasBNB`, data, {
    headers: authHeader(),
  });
export const transferGasEth = (data) =>
  axiosInstance.post(`${API_URL}transferGasEth`, data, {
    headers: authHeader(),
  });
export const transferGasMatic = (data) =>
  axiosInstance.post(`${API_URL}transferGasMatic`, data, {
    headers: authHeader(),
  });
export const transferCoinBalanceIntoMasterWallet = (data) =>
  axiosInstance.post(`${API_URL}transferCoinBalanceIntoMasterWallet`, data, {
    headers: authHeader(),
  });
export const transferSolTokenToMasterWallet = (data) =>
  axiosInstance.post(`${API_URL}transferSolTokenToMasterWallet`, data, {
    headers: authHeader(),
  });
export const transferSolBalanceIntoMasterWallet = (data) =>
  axiosInstance.post(`${API_URL}transferSolBalanceIntoMasterWallet`, data, {
    headers: authHeader(),
  });
export const transferTokenEth = (data) =>
  axiosInstance.post(`${API_URL}transferTokenEth`, data, {
    headers: authHeader(),
  });
export const transferTokenMatic = (data) =>
  axiosInstance.post(`${API_URL}transferTokenMatic`, data, {
    headers: authHeader(),
  });
export const transferTokenBNB = (data) =>
  axiosInstance.post(`${API_URL}transferTokenBNB`, data, {
    headers: authHeader(),
  });
export const transferGasSol = (data) =>
  axiosInstance.post(`${API_URL}transferGasSol`, data, {
    headers: authHeader(),
  });
export const addNewWalletSol = (data) =>
  axiosInstance.post(`${API_URL}addNewWalletSol`, data, {
    headers: authHeader(),
  });
export const addPrivacyOrCookies = (data) =>
  axiosInstance.post(`${API_URL}addPrivacyOrCookies`, data, {
    headers: authHeader(),
  });
export const getRequest = (data) =>
  axiosInstance.post(`${API_URL}getRequest`, data, {
    headers: authHeader(),
  });
export const createAccount = (data) =>
  axiosInstance.post(`${API_URL}createAccount`, data, {
    headers: authHeader(),
  });
export const updateSubAccountRequest = (data) =>
  axiosInstance.post(`${API_URL}updateSubAccountRequest`, data, {
    headers: authHeader(),
  });
export const getSubAccounts = (data) =>
  axiosInstance.post(`${API_URL}getSubAccounts`, data, {
    headers: authHeader(),
  });
export const getAllProductadmin = (data) =>
  axiosInstance.post(`${API_URL}getAllProductadmin`, data, {
    headers: authHeader(),
  });
export const updateProductadmin = (data) =>
  axiosInstance.post(`${API_URL}updateProductadmin`, data, {
    headers: authHeader(),
  });
export const stopSubAccountRequest = (data) =>
  axiosInstance.post(`${API_URL}stopSubAccountRequest`, data, {
    headers: authHeader(),
  });
export const editTargetValue = (data) =>
  axiosInstance.post(`${API_URL}editTargetValue`, data, {
    headers: authHeader(),
  });
export const setTargetValue = (data) =>
  axiosInstance.post(`${API_URL}setTargetValue`, data, {
    headers: authHeader(),
  });
export const getTargetData = () =>
  axiosInstance.get(`${API_URL}getTargetData`, {
    headers: authHeader(),
  });

export const getNewsAdmin = (data) =>
  axiosInstance.post(`${API_URL}getNewsAdmin`, data, { headers: authHeader() });
export const addNews = (data) =>
  axiosInstance.post(`${API_URL}addNews`, data, {
    headers: authHeader(),
  });
export const getPrivacyOrCookies = (data) =>
  axiosInstance.post(`${API_URL}getPrivacyOrCookies`, data, {
    headers: authHeader(),
  });
export const deleteNews = (data) =>
  axiosInstance.post(`${API_URL}deleteNews`, data, {
    headers: authHeader(),
  });
export const editNews = (data) =>
  axiosInstance.post(`${API_URL}editNews`, data, {
    headers: authHeader(),
  });
export const addPattner = (data) =>
  axiosInstance.post(`${API_URL}addPartner`, data, {
    headers: authHeader(),
  });
export const deletePartner = (data) =>
  axiosInstance.post(`${API_URL}deletePartner`, data, {
    headers: authHeader(),
  });
export const setWithdrawLimit = (data) =>
  axiosInstance.post(`${API_URL}setWithdrawLimit`, data, {
    headers: authHeader(),
  });
export const editWithdrawLimit = (data) =>
  axiosInstance.post(`${API_URL}editWithdrawLimit`, data, {
    headers: authHeader(),
  });
export const editPartner = (data) =>
  axiosInstance.post(`${API_URL}editPartner`, data, {
    headers: authHeader(),
  });
export const blockUnblockWithdraw = (data) =>
  axiosInstance.post(`${API_URL}blockUnblockWithdraw`, data, {
    headers: authHeader(),
  });
export const getRefferenceCodes = (data) =>
  axiosInstance.post(`${API_URL}getReferralCode`, data, {
    headers: authHeader(),
  });
export const createRefferenceCodes = (data) =>
  axiosInstance.post(`${API_URL}createReferralCode`, data, {
    headers: authHeader(),
  });
export const deleteReferralCode = (data) =>
  axiosInstance.post(`${API_URL}deleteReferralCode`, data, {
    headers: authHeader(),
  });
export const updateRefferenceCode = (data) =>
  axiosInstance.post(`${API_URL}updateReferralCode`, data, {
    headers: authHeader(),
  });
export const getPendingMigration = (data) =>
  axiosInstance.post(`${API_URL}getPendingMigration`, data, {
    headers: authHeader(),
  });
export const transferBSC = (data) =>
  axiosInstance.post(`${API_URL}transferBSC`, data, {
    headers: authHeader(),
  });
export const transferTron = (data) =>
  axiosInstance.post(`${API_URL}transferTron`, data, {
    headers: authHeader(),
  });
export const transferEth = (data) =>
  axiosInstance.post(`${API_URL}transferEth`, data, {
    headers: authHeader(),
  });
export const transferSolana = (data) =>
  axiosInstance.post(`${API_URL}transferSolana`, data, {
    headers: authHeader(),
  });
export const transferMatic = (data) =>
  axiosInstance.post(`${API_URL}transferMatic`, data, {
    headers: authHeader(),
  });
export const sendNotificationToUsers = (data) =>
  axiosInstance.post(`${API_URL}sendNotificationToUsers`, data, {
    headers: authHeader(),
  });
export const getNotificationToUsers = (data) =>
  axiosInstance.post(`${API_URL}getNotificationToUsers`, data, {
    headers: authHeader(),
  });
export const getPartner = (data) =>
  axiosInstance.get(`${API_URL}getPartner`, data, {});
export const getBlockUnblockWithdrawStatus = () =>
  axiosInstance.get(`${API_URL}getBlockUnblockWithdrawStatus`, {
    headers: authHeader(),
  });
export const getManulWithdrawStatus = () =>
  axiosInstance.get(`${API_URL}getManulWithdrawStatus`, {
    headers: authHeader(),
  });
export const getWithdrawLimit = () =>
  axiosInstance.get(`${API_URL}getWithdrawLimit`, {
    headers: authHeader(),
  });

export default {
  login,
  sendOtp,
  editToken,
  userChatStatus,
  deleteToken,
  addNewToken,
  getToken,
  sendOtpGeneral,
  resendOtpGeneral,
  varifyOtpGeneral,
  getUserInfo1,
  login1,
  verifyTwoFactor1,
  create,
  updateUserStatus,
  verifyTFAEmail,
  tfaEnable,
  tfaDisable,
  verifyTFA,
  rejectUser,
  approveUser,
  getApproverss,
  varifyOtp_wallet,
  editCategory_admin,
  editWallet,
  getChat,
  addNewWallet,
  userBalance,
  getWallet,
  addFee,
  editFee,
  listFee,
  getChatMessages,
  getFeeGraph,
  userGraph,
  recentUser,
  getTransactionDetails,
  getTopSellingProducts,
  getStoreReport,
  userDetails,
  getSaleGraph,
  adminSwitchProfile,
  getSaleReport,
  orderDetails,
  storeDetails,
  getRecentOrder,
  productDetails,
  searchTicket,
  markAllAsRead,
  getNotification,
  getTickets,
  updateTicketStatus,
  getTicketMessages,
  verifyUser,
  deactivaCategory,
  addNewCategory_admin,
  resetPasswordAdmin,
  changeStoreCategory,
  onOffUserTwoFactor,
  newOrderNewOrderGraph,
  approvedPendingStore_admin,
  userAndSaleDetails,
  getUserStores,
  getUserOrder,
  getUser,
  rejectPendingStore_admin,
  updateStoreStatus,
  getTotalBalance,
  getContractAmount,
  getReceiveAmount,
  getSendAmount,
  getContracts,
  getSafebox,
  getUsdFee,
  getUsdtFee,
  getEthFee,
  getEurFee,
  getGbpFee,
  getJypFee,
  getMatFee,
  getCtyFee,
  getPaxFee,
  getBusdFee,
  getBnbFee,
  getBTCFee,
  getSafeboxDetail,
  SendTwoFactor,
  approveContractOtp,
  getUserInfo,
  safeboxApprove,
  safeboxReject,
  approveExternalWalletOtp,
  calculateAdminCommissions,
  approveSenExternalWalletOtp,
  getIpfs,
  createMarketPlaceInternal,
  createMarketPlaceExternal,
  getCategory,
  transferStoreFee,
  checkStore,
  getMyStore,
  createProduct,
  getMyProduct,
  getProductData,
  getAllStore,
  searchAllStore,
  editMyStore,
  editStoreLogo,
  getMyOrders,
  getMyApproval,
  approveOrder,
  rejectOrder,
  getMyOrdersAsBuyer,
  submitRatting,
  updateOrderStatusSeller,
  getUserTotalBalance,
  getGlobalProducts,
  searchStoretByNameOrCategory,
  blockUnblockUser,
};
