import moment from "moment";
import { useEffect, useState } from "react";

import {
  getApproverss,
  rejectUser,
  approveUser,
  resetPasswordAdmin,
} from "../../../utils/services/user.service";
import "../ApproversList/table.css";

import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import OtcMarketplaceTable from "../ApproversList/component/OtcMarketplaceTable";
import ProjectRequestsTabls from "../ApproversList/component/ProjectRequestsTable";
function ProjectRequests() {
  const itemsPerPages = 20;
  const [selectedoption, setoptionSelect] = useState("all");
  const [BooleanState, setBooleanState] = useState("true");
  const [usersData, setUsersData] = useState([]);
  const [idQuery, setIdQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const [nameQuery, setNameQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenFactor, setIsModalOpenFactor] = useState(false);
  const [UserMail, setUserMail] = useState("");

  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [isactiveEnabled, setisactiveEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [pageCount, setPageCount] = useState(1); // Initialize with 1 page.
  const [ModalOpen, setModalOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [UnverifiedEmail, SetUnverifiedEmail] = useState("");

  const [emailReset, setEmailReset] = useState("");
  const [passwordReset, setPasswordReset] = useState("");
  const [approveOpen, setapproveOpen] = useState(false);
  const [rejectOpen, setrejectOpen] = useState(false);
  const [EyeOpen, setEyeOpen] = useState(false);
  const [textInput, setTextInput] = useState("");

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const getApproversss = async () => {
      try {
        setIsLoading(true);
        const payload = {
          limit: itemsPerPages,
          page_number: currentPage,
          approver_status: selectedoption,
        };
        const response = await getApproverss(payload);
        if (response.data.data) {
          setUsersData(response.data.data);

          const totalUsers = response.data.count;
          // console.log("totalUsers", totalUsers);

          const pages = Math.ceil(totalUsers / itemsPerPages);
          // console.log("pages", pages);
          handleCancel();
          setPageCount(pages);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          // console.log("data not found");
        }
      } catch (error) {
        setIsLoading(false);
        // console.log("error", error);
      }
    };
    getApproversss();
  }, [currentPage, selectedoption]);


  const handleCancel = () => {
    setModalOpen(false);
    setapproveOpen(false);
    setEyeOpen(false);
    setrejectOpen(false);
  };

  const customPaddingStyle = {
    padding: " 6px 4px",
  };
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          // background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>

      <ProjectRequestsTabls />
    </>
  );
}

export default ProjectRequests;
