import { useEffect, useState } from "react";
import { Ring } from "@uiball/loaders";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import { getAllTokens, getNotificationToUsers, getPendingMigration, getToken } from "../../utils/services/user.service";
import { chainIdToName, getStatusBadge } from "../../redux/constants/constans";
import { CustomSelect } from "../../shared/components/CustomSelect";
import styled from 'styled-components';
import { Button } from '@/shared/components/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import numeral from "numeral";
import moment from "moment";
import MigrationConfirmModa from "./MigrationConfirmModal";
import NotifyAllUsers from "./AddSafebox/NotifyAllUsersModal";
import Tooltip from "@/shared/components/Tooltip";


const NotifyUserTable = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showNotifyAllUserModal, setshowNotifyAllUserModal] = useState(false)

    const [reFetch, setRefetch] = useState(true)

    const [allMessages, setAllMessages] = useState([]);

    const fetchAllMessages = async () => {

        try {
            setIsLoading(true)
            const payload = { page_number: 1, limit: 1000 }

            const res = await getNotificationToUsers(payload)
            setAllMessages(res?.data?.data)
            setIsLoading(false)

        } catch (error) {
            console.log("🚀 ~ fetchAllMessages ~ error:", error)
            setIsLoading(false)

        }
    }
    useEffect(() => {
        fetchAllMessages()

    }, [])


    const header = [
        { accessor: "id", Header: "ID" },
        { accessor: "message", Header: "Message" },
        { accessor: "type", Header: "Type" },
        { accessor: "createdAt", Header: "Date Created" },

    ];

    const tableConfi2 = {
        isEditable: false,
        isSortable: false,
        isResizable: false,
        isPagination: true,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: "Search...",
        withPagination: true,
    };

    const data = [];
    allMessages?.map((item) =>
        data.push({

            id: (
                <div className="flex gap-1 items-center">
                    <h6
                        className="text-capitalize"
                        style={{ color: "#999999", fontSize: "inherit" }}
                    >
                        {item._id}
                    </h6>
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(item.user_id);
                            toast.success("user id copied to clipboard!");
                        }}
                        className="ml-2"
                        style={{ color: "lightblue" }}
                    >

                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>
            ),
            createdAt: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {moment(item.createdAt).format('lll') || "--"}
                </h6>
            ),
            type: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {item.type}
                </h6>
            ),
            message: (
                <div className="flex flex-row pr-5 items-center  gap-3 ">
                    <Tooltip text={item?.message} placement="top" style={{ cursor: "pointer" }}>
                        <h6 className="text-capitalize text-nowrap">
                            {item?.message}
                        </h6>

                    </Tooltip>
                </div>
            ),

        })
    );
    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>


            <div className="overflow-x-auto p-3 w-full">
                <h3 className="flex-col mr-3">Notify All Users </h3>

                <div className="flex flex-col md:flex-row mt-2 ">

                    <div className="col-md-3 mt-2 col-sm-12">
                        <InputDiv>
                            {/* <label
                                htmlFor="email"
                                className="text-white bg-transparent"
                                style={{ fontSize: "small" }}
                            >
                                Symbol
                            </label> */}
                            <FormButton variant="outline-primary" onClick={() => {
                                setshowNotifyAllUserModal(true)

                            }} type="submit" >
                                Notify
                            </FormButton>
                        </InputDiv>
                    </div>
                </div>


                <ReactTableBase
                    columns={header}
                    data={data}
                    tableConfig={tableConfi2}
                />
                {/* {
                    migrationReportData?.data?.length == 0 && (
                        <h3>No data found</h3>
                    )
                } */}
            </div>

            {
                showNotifyAllUserModal && <NotifyAllUsers show={showNotifyAllUserModal} hide={() => setshowNotifyAllUserModal(false)} message={allMessages} reFetch={() => setRefetch(!reFetch)} />
            }



        </>
    );
};
export default NotifyUserTable;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  input {
    background-color: transparent;
    border: none;
    color:white;
    border-bottom:1px solid #555555;
  }
  input.disabled {
    cursor: not-allowed !important;
    color: #d9d9d9;
  }
`;
const FormButton = styled(Button)`
 color:"white";
 margin-top: 1rem;
 `;