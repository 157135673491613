import moment from "moment";
import { useEffect, useState } from "react";
import TitleCard from "./TitleCard";
import Switch from "@mui/material/Switch";
import {
  getUser,
  verifyUser,
  onOffUserTwoFactor,
  resetPasswordAdmin,
  userChatStatus,
  getUserInfo1,
  blockUnblockUser,
  API_URL_BLANK,
  byPassFee,

} from "../../../utils/services/user.service";
import { Modal as BootstrapModal } from 'react-bootstrap';
import Tfaaa from "../../Account/MyAccount/Tfaaa";
import "./table.css";
import { Modal } from "@mui/material";
import ProfileModal from "./ProfileModal";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import Tooltip from "@/shared/components/Tooltip";
import { Link } from "react-router-dom";
import { Button, ButtonToolbar } from "@/shared/components/Button";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import DateRangeFilter from "../../../shared/components/DateRangeFilter/DateRangeFilter";
import LocationInfoModal from "./LocationInfoModal";
import styled from 'styled-components';
import { CustomSelect } from "@/shared/components/CustomSelect";


function Users() {
  const itemsPerPages = 20;
  const [selectedoption, setoptionSelect] = useState("all");
  const [BooleanState, setBooleanState] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [idQuery, setIdQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const [nameQuery, setNameQuery] = useState("");
  const [Blockvalue, setBlockvalue] = useState('');
  const [BlockUservalue, setBlockUservalue] = useState('');
  const [userId, setUserId] = useState('');
  const [Blockmail, setBlockmail] = useState('');
  const [BlockUsermail, setBlockUsermail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenFactor, setIsModalOpenFactor] = useState(false);
  const [isModalOpenBypass, setIsModalOpenBypass] = useState(false);
  const [isModalBlock, setisModalBlock] = useState(false);
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [isactiveEnabled, setisactiveEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [pageCount, setPageCount] = useState(1); // Initialize with 1 page.
  const [ModalOpen, setModalOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [UnverifiedEmail, SetUnverifiedEmail] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState();
  const [emailReset, setEmailReset] = useState("");
  const [passwordReset, setPasswordReset] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedDates, setSelectedDates] = useState({ from: '', to: '' });
  console.log("🚀 ~ Users ~ selectedDates:", selectedDates)
  const [modal, setModal] = useState(false);
  const [modalLimit, setModalLimit] = useState(false);
  const [locationData, setLocationData] = useState([]);

  const [sortOrder, setSortOrder] = useState('ASC');
  const [data, setData] = useState(['Banana', 'Apple', 'Orange', 'Mango']);
  const [selectedOption, setSelectedOption] = useState('createdAt'); // Default selected option
  const [selectetRole, setSelectedRole] = useState('all'); // Default selected option
  const [selctedUserSort, setSelectedUserSort] = useState('all'); // Default selected option



  const [TFA, setTFA] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [tfaUrl, setTfaUrl] = useState(false);
  const [tfaEmail, setTfaEmail] = useState("");
  const [showTfaModal, setShowTfaModal] = useState(false);
  const [isopenFactor, setisopenFactor] = useState(false);
  const [isVerifyTrue, setisVerifyTrue] = useState(false);
  const [isVerifyblock, setisVerifyblock] = useState(false);
  const [isSwitchblock, setisSwitchblock] = useState(false);
  const [byPasssData, setBypassData] = useState(null)


  const renderTfaModal = () => {
    return (
      <BootstrapModal
        show={showTfaModal}
        onHide={() => setShowTfaModal(false)}
        centered
        className="dark:bg-gray-800"
      >
        <BootstrapModal.Header
          closeButton
          className="text-white dark:border-gray-700 bg-dark"
        >
          <BootstrapModal.Title>TFA Modal</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="dark:text-white bg-dark">
          <Tfaaa
            tfaEmail={tfaEmail}
            tfaUrl={tfaUrl}
            onDataFromChild={handleDataFromChild}
          />
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <Button variant="secondary" onClick={() => setShowTfaModal(false)}>
            Close
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  };

  const handleDataFromChild = (data) => {
    sessionStorage.setItem("data", data);
    if (data === true) {
      setShowTfaModal(false);

      if (isopenFactor === true) {
        setIsModalOpenFactor(true);
        setisopenFactor(false)
      } else if (isVerifyTrue === true) {
        setModalOpen(true);
        setisVerifyTrue(false)
      } else if (isVerifyblock === true) {
        setisModalBlock(true);
        setisVerifyblock(false)
      } else if (isSwitchblock === true) {
        window.open(`${API_URL_BLANK}Admin_login`, "_blank");
      } else {
        setResetOpen(true);
      }

    } else {

      setTfaUrl(true);
      setTfaEmail(userData.data.user.email);
    }
  };


  useEffect(() => {
    const storedData = sessionStorage.getItem("data");
    if (storedData === "true") {
      handleDataFromChild(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        email: usermail,
      };

      try {
        const response = await getUserInfo1(requestData);

        if (response.status === 200) {
          setTFA(response.data.User.TFA);
        } else {
          console.error("getUserInfo1 failed:", response);
        }
      } catch (error) {
        console.error("Error getUserInfo1:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // // Set up interval to fetch data every second
    // const intervalId = setInterval(() => {
    //   fetchData();
    // }, 1000);

    // // Cleanup function to clear the interval when the component is unmounted
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, [usermail, TFA]);


  useEffect(() => {
    getUsers();
  }, [currentPage, nameQuery, emailQuery, idQuery, selctedUserSort, selectetRole, is2FAEnabled, BooleanState, selectedOption, sortOrder, selectedDates]);

  const getUsers = async () => {
    try {
      setIsLoading(true);
      const payload = {
        user_id: idQuery,
        email: emailQuery,
        user_name: nameQuery,
        two_factor: is2FAEnabled,
        // user_status: isactiveEnabled,
        limit: itemsPerPages,
        page_number: currentPage,
        is_verified_status: BooleanState,
        sorting_feild: selectedOption,
        login_status: selctedUserSort,
        role: selectetRole,
        sorting: sortOrder,
        fromDate: selectedDates.from,
        toDate: selectedDates.to

      };
      const response = await getUser(payload);
      if (response.data.user.result) {
        setUsersData(response.data.user.result);

        const totalUsers = response.data.user.count;
        // console.log("totalUsers", totalUsers);

        const pages = Math.ceil(totalUsers / itemsPerPages);
        // console.log("pages", pages);

        setPageCount(pages);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // console.log("data not found");
      }
    } catch (error) {
      setIsLoading(false);
      // console.log("error", error);
    }
  };


  const ResetUsers = async () => {
    try {
      setIsLoading(true);
      const payload = {
        user_id: "",
        email: "",
        user_name: "",
        two_factor: false,
        // user_status: isactiveEnabled,
        limit: itemsPerPages,
        page_number: currentPage,
        is_verified_status: BooleanState,
      };
      const response = await getUser(payload);
      if (response.data.user.result) {
        setUsersData(response.data.user.result);

        const totalUsers = response.data.user.count;
        // console.log("totalUsers", totalUsers);

        const pages = Math.ceil(totalUsers / itemsPerPages);
        // console.log("pages", pages);

        setPageCount(pages);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // console.log("data not found");
      }
    } catch (error) {
      setIsLoading(false);
      // console.log("error", error);
    }
  };
  const handleconfirm = async () => {
    setModalOpen(false);

    try {
      setIsLoading(true);
      const payload = {
        email: UnverifiedEmail,
      };
      const response = await verifyUser(payload);
      if (response.data.success === true) {
        getUsers();

        toast.success("User Verified Successfully", response.data.Message);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error(response.data.Message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("unable to register user please try again");
    }
  };

  const handleUnverifiedButtonClick = (user) => {
    const email = user.email;
    SetUnverifiedEmail(email);
    if (TFA) {
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
      setisVerifyTrue(true)
    } else {
      setModalOpen(true);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setModalOpen(false);
    setisModalBlock(false)
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleToggle2Factor = (value) => {
    setIs2FAEnabled(value);
  };

  const handleToggleActive = (value) => {
    setisactiveEnabled(value);
  };

  const openFactor = () => {
    if (TFA) {
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
      setisopenFactor(true)
    } else {
      setIsModalOpenFactor(true);
    }
  };

  const handleApiChange = (value) => {
    const selectedOption = value.value;

    // console.log("selectedOption>>>>>", typeof selectedOption);
    // console.log("selectedOption>>>>>gggggg", selectedOption);

    setoptionSelect(selectedOption);
    // console.log("setoptionSelect>>>>>", setoptionSelect);

    let booleanValue;

    if (selectedOption === "true") {
      booleanValue = true;
    } else if (selectedOption === "false") {
      booleanValue = false;
    } else {
      booleanValue = true;
    }

    setBooleanState(booleanValue);
    // console.log("setBooleanState", setBooleanState);
    // console.log("1233333333333333333333333", booleanValue);
  };

  const reset = () => {
    setIdQuery("");
    setEmailQuery("");
    setNameQuery("");
    setoptionSelect("all");
    setIs2FAEnabled(false);
    setisactiveEnabled(false);
    setBooleanState(true);
    ResetUsers();

    // console.log("BooleanState 45454545454", BooleanState);
  };

  const handleResetClick = async () => {
    setResetOpen(false);
    try {
      setIsLoading(true);
      const payload = {
        email: emailReset,
        password: passwordReset,
      };
      const response = await resetPasswordAdmin(payload);
      if (response.data.status === "fail") {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
        setIsLoading(false);
        // console.log("response reset", response);
      }
    } catch (error) {
      toast.error(
        "Password at least one uppercase letter, one lowercase letter and one number and one special character and minimum 8 character"
      );
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleCancelClick = () => {
    setResetOpen(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlleToggle2Factor = async (value, userId) => {
    try {
      setIsModalOpenFactor(false);
      setIsLoading(true);
      const user_id = userId;
      const status = value;

      const payload = {
        user_id: user_id,
        status: status,
      };

      const response = await onOffUserTwoFactor(payload);

      if (response.data) {
        const updatedUsersData = usersData.map((user) => {
          if (user._id === userId) {
            return { ...user, status_2fa: value }; // Use the updated boolean value here
          }
          return user;
        });

        setUsersData(updatedUsersData);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle API call errors
      console.error("Error toggling 2FA:", error);
    }
  };

  // Function to handle click on action (Open modal)
  const handleActionClick = (user) => {
    setLocationData(user)
    setModal(true);
  };

  // Function to close the modal
  const closeOpenedModal = () => {
    setModal(false);
  };

  const handleBlockDropdownChange = (e, mail) => {
    const selectedValue = e.target.value;
    if (TFA) {
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
      setisVerifyblock(true)
      setBlockvalue(selectedValue)
      setBlockmail(mail)
    } else {
      setisModalBlock(true)
      setBlockvalue(selectedValue)
      setBlockmail(mail)
    }
  };

  const handleUserBlockDropdownChange = (e, id) => {
    // console.log("🚀 ~ handleUserBlockDropdownChange ~ id:", id)
    const selectedValue = e.target.value;
    // console.log("🚀 ~ handleUserBlockDropdownChange ~ selectedValue:", selectedValue)
    setisModalBlock(true)
    setBlockUservalue(selectedValue)
    setBlockUsermail(id)
    setUserId(id)
  };

  const handlleActiveBlock = async () => {
    const payload = {
      email: Blockmail, // Replace with the actual email value
      chat_status: Blockvalue, // Replace with the actual chat status value
    };

    try {
      setIsLoading(true);
      const response = await userChatStatus(payload);

      if (response.status === 200) {
        setBlockmail('')
        setBlockvalue('')
        setBlockUservalue('')
        setBlockUsermail('')
        getUsers()
        toast.success('User chat status updated successfully')
        // console.log('API request successful');
        setIsLoading(false);
      } else {
        // Handle error
        // console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      // Handle network or other errors
      setIsLoading(false);
      // console.error('Error during API request:', error);
    }
  };
  const handlleActiveBlockUser = async () => {
    const payload = {
      status: BlockUservalue, // Replace with the actual email value
      user_id: userId, // Replace with the actual chat status value
    };
    // console.log("🚀 ~ handlleActiveBlock ~ payload:", payload)

    try {
      setIsLoading(true);
      const response = await blockUnblockUser(payload);

      if (response.status === 200) {
        setBlockUservalue('')
        setBlockUsermail('')
        getUsers()
        toast.success('User status updated successfully')
        // console.log('API request successful');
        setIsLoading(false);
      } else {
        // Handle error
        console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      // Handle network or other errors
      setIsLoading(false);
      console.error('Error during API request:', error);
    }
  };
  const handleBlockYes = () => {
    setisModalBlock(false);
    if (Blockmail && Blockvalue) {

      handlleActiveBlock()
    } else {
      handlleActiveBlockUser()
    }
  }

  const handleDateChange = (dates) => {
    setSelectedDates({
      from: isNaN(Date.parse(dates.from)) ? "" : new Date(dates.from).toDateString(),
      to: isNaN(Date.parse(dates.to)) ? "" : new Date(dates.to).toDateString(),
    });
  };


  // sorting functionality 
  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleSortingChange = (e) => {
    const newValue = e.value;
    setSelectedOption(newValue);
  };
  const handleSortingChangerole = (value) => {
    const newValue = value.value;
    setSelectedRole(newValue);
  };
  const handleSortingChangeUsers = (e) => {
    const newValue = e.value;
    setSelectedUserSort(newValue);
  };

  const confirmationMessage =
    Blockvalue === "enabled" || BlockUservalue === "enabled"
      ? "Are you sure you want to  unblock this user?"
      : Blockvalue === "disabled" || BlockUservalue === "disabled"
        ? "Are you sure you want to block this user?"
        : "Invalid Blockvalue";

  const customPaddingStyle = {
    padding: " 6px 4px", // Top, Right, Bottom, Left
  };
  const Ava = `/img/avat.png`;


  const booleanOptions = [
    // { value: "all", label: "All" },
    { value: true, label: "Verified" },
    { value: false, label: "Unverified" },
  ];
  const optionsArray = [
    { value: "all", label: "All" },
    { value: "user", label: "User" },
    { value: "company", label: "Company" },
    { value: "project", label: "Project" },
  ];

  const optionsRoleStatus = [
    { value: "all", label: "All" },
    { value: true, label: "Active Users" },
    { value: false, label: "Inactive Users" },
  ];
  const optionsSortlogin = [
    { value: "createdAt", label: "Created date" },
    { value: "last_login", label: "Last login" },
    { value: "last_login_failed", label: "Last login Failed" },
  ];


  const handleBypassClick = async () => {
    try {
      setIsLoading(true)

      const payload = {
        user_id: byPasssData._id, status: byPasssData?.by_pass_fee ? false : true
      }
      const res = await byPassFee(payload)
      console.log("🚀 ~ handleBypassClick ~ res:", res)
      setIsLoading(false)
      setIsModalOpenBypass(false)
      toast.success(res?.data?.Message)
      getUsers()


    } catch (error) {
      console.log("🚀 ~ handleBypassClick ~ error:", error)
      setIsLoading(false)
      setIsModalOpenBypass(false)

    }

  }

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>

      <div>
        {/* Render the Modal component */}
        <ProfileModal isOpen={isModalOpen} onClose={closeModal} />
      </div>

      <div className="flex px-2">
        <TitleCard title="">
          <h3 className='mb-2'>Apply Filters</h3>

          <div className=' lg:flex lg:space-x-4 sm:w-full'>

            {/* <div className="flex flex-col mb-4 lg:w-1/4 lg:mb-0">
              <select
                onChange={handleApiChange}
                className="rounded-lg "
                style={customPaddingStyle}
                id="filters"
              >

                <option selected={BooleanState === true} value="true" >
                  Verified
                </option>
                <option selected={BooleanState === false} value="false" >
                  Unverified
                </option>
              </select>

            </div> */}
            <div className="flex flex-col mb-4 lg:w-1/4 lg:mb-0">
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-0 mt-2"
              >
                Type
              </label>
              <InputDiv className="px-0">
                <DropdownDiv className="mb-0" style={{ textTransform: "Capitalize" }}>
                  <CustomSelect
                    name="filters"
                    id="filters"
                    className="w-25 px-0"
                    value={selectedoption}
                    onChange={handleApiChange}

                    options={booleanOptions}
                  />
                </DropdownDiv>
              </InputDiv>
            </div>

            <div className="flex flex-col mb-4 lg:w-1/4 lg:mb-0">




              <InputDiv>
                <label
                  style={{ fontSize: "small" }}
                  htmlFor="features"
                  className="form-label text-white mb-1"
                >
                  User Id
                </label>
                <ChatSearchInput
                  type="text"
                  value={idQuery}
                  onChange={(e) => setIdQuery(e.target.value)}
                  className="form-control mt-3"
                  placeholder="Search by User ID"

                />
              </InputDiv>
            </div>
            <div className="flex flex-col mb-4 lg:w-1/4 lg:mb-0">

              <InputDiv>
                <label
                  style={{ fontSize: "small" }}
                  htmlFor="features"
                  className="form-label text-white mb-1"
                >
                  Email
                </label>
                <ChatSearchInput
                  type="text"
                  value={emailQuery}
                  onChange={(e) => setEmailQuery(e.target.value)}
                  className="form-control mt-3"
                  placeholder="Search by Email"

                />
              </InputDiv>
            </div>
            <div className="flex flex-col mb-4 lg:w-1/4 lg:mb-0">


              <InputDiv>
                <label
                  style={{ fontSize: "small" }}
                  htmlFor="features"
                  className="form-label text-white mb-1"
                >
                  Name
                </label>
                <ChatSearchInput
                  type="text"
                  value={nameQuery}
                  onChange={(e) => setNameQuery(e.target.value)}
                  className="form-control mt-3"
                  placeholder="Search by Name"

                />
              </InputDiv>
            </div>
            <div className='flex '>
              <div className="flex flex-col mb-4 lg:mb-0">

                <h6 className="text-white text-nowrap ">Two-Factor</h6>
                <Switch
                  checked={is2FAEnabled}
                  onChange={(event) => handleToggle2Factor(event.target.checked)}
                  name={`toggle_2fa`}
                  color="success"
                  className="mt-3"
                />
              </div>

            </div>

          </div>



          <div className=" flex flex-col lg:flex-row w-[100%] items-center gap-4 ">

            <div className="flex flex-row items-center gap-2">
              <Tooltip text="Switch Profile" placement="bottom">
                <FormButton variant="outline-primary" type="submit">
                  <a
                    href={TFA ? "#" : `${API_URL_BLANK}Admin_login`}
                    target={TFA ? "" : "_blank"}
                    // className="flex h-10 px-4 items-center  font-bold text-white bg-blue-400 rounded-lg sm:flex"
                    onClick={() => {
                      if (TFA) {
                        setTfaEmail(userData.data.user.email);
                        setTfaUrl(true);
                        setShowTfaModal(true);
                        setisSwitchblock(true)
                      }
                    }}
                  >
                    Switch
                  </a>
                </FormButton>
              </Tooltip>

              <ButtonToolbar className="flex h-10 self-center   ">
                <Tooltip text="Reset Password" placement="bottom">
                  <button
                    onClick={() => {
                      if (TFA) {
                        setTfaEmail(userData.data.user.email);
                        setTfaUrl(true);
                        setShowTfaModal(true);
                      } else {
                        setResetOpen(true);
                      }
                    }}
                    className="h-10 px-3   text-sm font-bold text-white bg-blue-400 rounded-lg w-42"
                  >
                    <FontAwesomeIcon
                      icon={faKey}
                      style={{ transform: "rotate(-90deg)" }}
                    />
                  </button>
                </Tooltip>
              </ButtonToolbar>
            </div>
            <div className="flex flex-col items-center text-white ">
              <DateRangeFilter onDateChange={handleDateChange} />
              {/* <p>Selected Dates: {selectedDates.from} to {selectedDates.to}</p> */}

            </div>

            <div className="flex flex-col mb-4 w-full lg:w-1/4 lg:mb-0">

              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-0 mt-2"
              >
                Role
              </label>
              <InputDiv className="px-0">
                <DropdownDiv className="mb-0" style={{ textTransform: "Capitalize" }}>
                  <CustomSelect
                    name="filters"
                    id="filters"
                    className="w-25 px-0"
                    value={selectetRole}
                    onChange={handleSortingChangerole}

                    options={optionsArray}
                  />
                </DropdownDiv>
              </InputDiv>
            </div>

            <div className="flex flex-col w-full mb-4 lg:w-1/4 lg:mb-0">
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-0 mt-2"
              >
                Role Status
              </label>
              <InputDiv className="px-0">
                <DropdownDiv className="mb-0" style={{ textTransform: "Capitalize" }}>
                  <CustomSelect
                    name="filters"
                    id="filters"
                    className="w-25 px-0"
                    onChange={handleSortingChangeUsers}
                    value={selctedUserSort}

                    options={optionsRoleStatus}
                  />
                </DropdownDiv>
              </InputDiv>
            </div>




          </div>
          <div className="flex py-7 flex-col md:flex-row w-[100%] items-center gap-4 ">


            <div className="flex flex-col w-full mb-4 lg:w-1/4 lg:mb-0">

              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-0 mt-2"
              >
                Sort Login
              </label>
              <InputDiv className="px-0">
                <DropdownDiv className="mb-0" style={{ textTransform: "Capitalize" }}>
                  <CustomSelect
                    name="filters"
                    id="filters"
                    className="w-25 px-0"
                    value={selectedOption}
                    onChange={handleSortingChange}

                    options={optionsSortlogin}
                  />
                </DropdownDiv>
              </InputDiv>
            </div>
            <div className="flex flex-row gap-4">
              <label className="flex gap-2 text-white">
                <input
                  type="radio"
                  value="ASC"
                  checked={sortOrder === 'ASC'}
                  onChange={handleSortChange}
                />
                Ascending
              </label>
              <label className="flex gap-2 text-white" >
                <input
                  type="radio"
                  value="DES"
                  checked={sortOrder === 'DES'}
                  onChange={handleSortChange}
                />
                Descending
              </label></div>
          </div>


          <div className="w-full overflow-x-auto">
            <table className="table w-full tbody">
              <thead>
                <tr className="text-nowrap">
                  {/* <th>User Profile</th> */}
                  <th>Name</th>
                  <th>created</th>
                  <th>Email Id</th>
                  <th>Two Factor</th>
                  <th>Bypass Fee</th>
                  <th>Chat</th>
                  <th>Last login</th>
                  <th>Login failed</th>
                  <th>Block</th>
                  <th>Verified</th>
                  <th>Vat Number</th>
                  <th>Role</th>
                  <th>KYC Status</th>
                  <th>Balance Info</th>
                  <th>Documents</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="text-nowrap ">
                {usersData.length > 0 ? (
                  usersData.map((user) => (
                    <tr key={user._id}>
                      <td>
                        <div className="flex flex-row pr-5 items-center justify-between gap-3 ">
                          <img className="flex h-[44px] w-[44px] rounded-3xl" src={user.image || Ava} />
                          <span>
                            {user.isverified === false ? user.firstName : user.name}
                          </span>
                        </div>
                      </td>
                      <td className="hover-date">
                        {user.createdAt
                          ? moment(user.createdAt).fromNow()
                          : "N/A"}
                        <span className="date-on-hover">
                          {user.createdAt
                            ? moment(user.createdAt).format("DD MMMM YYYY")
                            : "N/A"}
                        </span>
                      </td>
                      <td>{user.email}</td>

                      <td className="font-bold">
                        <Switch
                          checked={user.status_2fa}
                          onClick={() => {
                            setSelectedUserId(user._id);
                            setSelectedStatus(!user.status_2fa);
                            openFactor();
                          }}
                          name={`toggle_2fa_${user._id}`}
                          color="success"
                        />
                      </td>

                      <td className="font-bold">
                        <Switch
                          checked={user?.by_pass_fee || false}
                          onClick={() => {
                            setIsModalOpenBypass(true)
                            setBypassData(user)

                          }}
                          name={`toggle_2fa_${user._id}`}
                          color="success"
                        />
                      </td>
                      <td>
                        <select
                          className="rounded-lg text-midnight"
                          value={user.chat_status !== undefined ? user.chat_status : 'enabled'}
                          onChange={(e) => handleBlockDropdownChange(e, user.email)}
                          style={{
                            color: user.chat_status === 'disabled' ? 'red' : 'green',
                          }}
                        >
                          <option value="enabled" style={{ color: 'green' }}>
                            Enable
                          </option>
                          <option value="disabled" style={{ color: 'red' }}>
                            Block
                          </option>
                        </select>
                      </td>

                      <td className="hover-date">
                        {user.last_login
                          ? moment(user.last_login).fromNow()
                          : "N/A"}
                        <span className="date-on-hover">
                          {user.last_login
                            ? moment(user.last_login).format("DD MMMM YYYY")
                            : "N/A"}
                        </span>
                      </td>
                      <td className="hover-date">
                        {user.last_login_failed
                          ? moment(user.last_login_failed).fromNow()
                          : "N/A"}
                        <span className="date-on-hover">
                          {user.last_login_failed
                            ? moment(user.last_login_failed).format("DD MMMM YYYY")
                            : "N/A"}
                        </span>
                      </td>

                      <td>
                        {/* block section  */}
                        <select
                          className="rounded-lg text-midnight"
                          value={user.status !== undefined ? user.status : 'enabled'}
                          onChange={(e) => handleUserBlockDropdownChange(e, user?._id)}
                          style={{
                            color: user.status === undefined || user.status === 'enabled' ? 'green' : 'red',
                          }}
                        >
                          <option value="enabled" style={{ color: 'green' }}>
                            Enable
                          </option>
                          <option value="disabled" style={{ color: 'red' }}>
                            Block
                          </option>
                        </select>
                      </td>

                      <td>
                        {user.isverified === false ? (
                          <div>
                            <button
                              type="button"
                              className="text-yellow-500"
                              onClick={() => handleUnverifiedButtonClick(user)}
                            >
                              Unverified
                            </button>
                          </div>
                        ) : (
                          <div>
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ color: "#33d21e" }}
                            />
                          </div>
                        )}
                      </td>
                      <td className="hover-date">
                        {user.vat_number
                          ? user?.vat_number
                          : "N/A"}

                      </td>
                      <td className="hover-date">
                        {user.role
                          ? user?.role
                          : "N/A"}

                      </td>
                      <td className="hover-date">
                        {user.kyc_status
                          ? user?.kyc_status
                          : "N/A"}

                      </td>

                      <td className="">
                        <Tooltip text="View User's all balance report" placement="bottom">
                          <Link to={`/balance-report/${user?._id}`}>

                            <img className="w-[20px] mt-2  self-center cursor-pointer" onClick={() => handleActionClick(user)} src="/img/eye.png" alt="Eye" />

                          </Link>
                        </Tooltip>
                      </td>
                      <td className="">
                        {user?.profile_document?.length ? <img className="w-[20px] mt-2  self-center cursor-pointer" onClick={() => { window.open(user?.profile_document[0]) }} src="/img/eye.png" alt="Eye" /> : '--'
                        }
                      </td>

                      <td className="flex items-center justify-center">
                        <img className="w-[20px] mt-2  self-center cursor-pointer" onClick={() => handleActionClick(user)} src="/img/eye.png" alt="Eye" />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No users available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Pagination */}
          {pageCount > 1 && (
            <div className="flex items-center justify-center mt-4">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                className={`flex items-center justify-center px-1 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="w-2.5 h-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
              </button>

              {pageCount <= 3 ? (
                Array.from({ length: pageCount }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentPage(index + 1)}
                    className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === index + 1
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                  >
                    {index + 1}
                  </button>
                ))
              ) : (
                <>
                  {currentPage > 1 && (
                    <>
                      <button
                        onClick={() => setCurrentPage(1)}
                        className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === 1
                          ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          }`}
                      >
                        1
                      </button>
                      {currentPage > 3 && <span className=" dots">....</span>}
                    </>
                  )}
                  {currentPage > 2 && (
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === currentPage - 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        }`}
                    >
                      {currentPage - 1}
                    </button>
                  )}
                  <button
                    className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === currentPage
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                  >
                    {currentPage}
                  </button>
                  {currentPage < pageCount - 1 && (
                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === currentPage + 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        }`}
                    >
                      {currentPage + 1}
                    </button>
                  )}
                  {currentPage < pageCount && (
                    <>
                      {currentPage < pageCount - 2 && (
                        <span className="dots">....</span>
                      )}
                      <button
                        onClick={() => setCurrentPage(pageCount)}
                        className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === pageCount
                          ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                          }`}
                      >
                        {pageCount}
                      </button>
                    </>
                  )}
                </>
              )}
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === pageCount}
                className={`flex items-center justify-center px-1 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
              >
                <span className="sr-only">Next</span>
                <svg
                  className="w-2.5 h-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </div>
          )}
        </TitleCard>
      </div>
      <Modal
        open={ModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        {/* <div className="absolute inset-0 bg-black bg-opacity-50"></div> */}
        <div className="p-4 bg-white rounded-lg custom-dialog bg-opacity-20 w-72">
          <p className="mb-4 text-lg font-semibold text-black">
            Are you sure you want to Verify the User?
          </p>
          <div className="flex justify-end">
            <button
              onClick={handleCancel}
              className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300"
            >
              No
            </button>
            <button
              onClick={handleconfirm}
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={isModalOpenFactor}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        <div className="p-4 bg-white rounded-lg custom-dialog bg-opacity-20 w-72">
          <p className="mb-4 text-lg font-semibold text-black">
            Are you sure you want to change the Two-Factor Status?
          </p>
          <div className="flex justify-end">
            <button
              onClick={() => {
                setIsModalOpenFactor(false);
              }}
              className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300"
            >
              No
            </button>
            <button
              onClick={() => {
                setIsModalOpenFactor(false);
                handlleToggle2Factor(selectedStatus, selectedUserId);
              }}
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={isModalOpenBypass}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        <div className="p-4 bg-white rounded-lg custom-dialog bg-opacity-20 ">
          <p className="mb-4 text-lg font-semibold text-black">
            Are you sure you want to turn {byPasssData?.by_pass_fee ? "Off" : "On"} Bypass Fee?
          </p>
          <div className="flex justify-end">
            <button
              onClick={() => {
                setIsModalOpenBypass(false);
              }}
              className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300"
            >
              No
            </button>
            <button
              onClick={() => {
                handleBypassClick()
              }}
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={resetOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        <div className="p-4 bg-white rounded-lg custom-dialog bg-opacity-20 w-72">
          <p className="mb-4 text-lg font-semibold text-black">
            Reset Password
          </p>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full p-2 mt-1 border rounded"
              placeholder="Enter your email"
              value={emailReset}
              onChange={(e) => setEmailReset(e.target.value)}
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              className="w-full p-2 pr-10 mt-1 border rounded"
              placeholder="Enter your new password"
              value={passwordReset}
              onChange={(e) => setPasswordReset(e.target.value)}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 flex items-center mr-2 focus:outline-none"
            >
              <FontAwesomeIcon
                className="mt-8"
                icon={showPassword ? faEye : faEyeSlash}
              />
            </button>
          </div>
          <div className="flex justify-between">
            <button
              // type="button"
              onClick={handleCancelClick}
              className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              // type="button"
              onClick={handleResetClick}
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Reset
            </button>
          </div>
        </div>
      </Modal>
      {showTfaModal && renderTfaModal()}

      <Modal
        open={isModalBlock}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        <div className="p-4 bg-white rounded-lg custom-dialog bg-opacity-20 w-72">
          <p className="mb-4 text-lg font-semibold text-black">
            {confirmationMessage}
          </p>
          <div className="flex justify-end">
            <button
              onClick={() => {
                setisModalBlock(false);
              }}
              className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300"
            >
              No
            </button>
            <button
              onClick={handleBlockYes}
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>



      {
        modal && <LocationInfoModal show={modal} hide={() => setModal(false)} data={locationData} />
      }

    </>
  );
}

export default Users;
const FormButton = styled(Button)`
 color:"white";
 margin-top: 1rem;
 `;

const InputDiv = styled.div`
width: 100%;
display: flex;
flex-direction: column;
color: white;
padding: 5px 0px;
font-size: 14px;
`;

const DropdownDiv = styled.div`
display: block;
@media screen and (max-width: 876px) {
  width: 100%;
}
`;

const ChatSearchInput = styled.input`
width: 100%;
height: 100%;
font-size: 12px;
transition: all 0.3s;
background: transparent;
border: none;
padding-left: 2px;
border-bottom: 1px solid #b1b1b1;
border-top-right-radius: 0px;
border-top-left-radius: 0px;
border-bottom-right-radius: 0px;
border-bottom-left-radius: 0px;
color: #b1b1b1;

&:focus,
&:active {
  outline: none;
  box-shadow: none;
  color: #b1b1b1;
  background: transparent;
  border-bottom: 2px solid #47c9a4;
}

&::-webkit-input-placeholder {
  color: #b1b1b1;
}
&::-moz-placeholder {
  color: #b1b1b1;
}
&:-moz-placeholder {
  color: #b1b1b1;
}
&:-ms-input-placeholder {
  color: #b1b1b1;
}
`;