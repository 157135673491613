import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Scrollbar from "@/shared/components/ScrollBar";
import { CurrentUserProps, ContactsProps } from "@/shared/prop-types/ChatProps";
import {
  colorAdditional,
  colorBackground,
  colorBackgroundBody,
  colorFieldsBorder,
  colorAccent,
  colorAccentHover,
  colorBubbleActive,
  colorText,
  colorBubble,
} from "@/utils/palette";
import io from "socket.io-client";
import config from "../../../../config/config";
import {
  paddingRight,
  paddingLeft,
  borderRight,
  left,
  right,
  marginRight,
} from "@/utils/directions";
import ChatContact from "./ChatContact";
import ChatBubble from "./ChatBubble";
import ChatSearch from "./ChatSearch";
import ChatField from "./ChatField";
import ChatTopbar from "./ChatTopbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Ring } from "@uiball/loaders";
// import socketIOClient from 'socket.io-client';
import {
  getChatMessages,
  getChat,
} from "../../../../utils/services/user.service";
import { useChainContext } from "../../../context/context";

const Chat = ({ currentUser, contacts }) => {
  const [currentMessages, setCurrentMessages] = useState(contacts[0]?.messages);
  const [currentChat, setCurrentChat] = useState(contacts[0]?.user_id);
  const [currentChat1, setCurrentChat1] = useState(contacts[0]?.user_id);
  const [openContacts, setOpenContacts] = useState(false);
  const [ChatId, setChatId] = useState();
  const [UserId, setUserId] = useState();
  const [UserName, setUserName] = useState();
  const [ChatMessage, setChatMessage] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [roomData, setroomData] = useState([]);
  const [userChats, setUserChats] = useState([]);
  const [NewMessage, setNewMessage] = useState("");
  const [messagesArray, setMessagesArray] = useState([]);
  const currentTime = new Date();
  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const [selectedChat, setSelectedChat] = useState(false);
  const [ShowcurrentChat, setShowCurrentChat] = useState();
  const [currentView, setCurrentView] = useState("chats");
  const [limit, setLimit] = useState(10); // initialLimit is your default limit
  const [page, setPage] = useState(1);
  const [uniqueMessageIds, setUniqueMessageIds] = useState(new Set());
  //////console.log('ShowcurrentChat >>>>>>>>>>>>>>>>>>>>>>>>', ShowcurrentChat)
  //////console.log('uniqueMessageIds >>>>>>>>>>>>>>>>>>>>>>>>', uniqueMessageIds)
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChatId, setselectedChatId] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const adminId = JSON.parse(sessionStorage.getItem("user")).data.user.user_id;
  //  console.log('chatData oldddddddddddddddddd', chatData)
  // console.log("filteredData filteredData", filteredData);
  // console.log('userChats oldddddddddddddddddd', userChats)
  // console.log('roomData oldddddddddddddddddd', roomData)
  // console.log('chatData UserId', UserId)
  // console.log("chatData messagesArray ", messagesArray);
  //////console.log('chatData NewMessage', NewMessage)
  //////console.log('ChatMessage', ChatMessage);
  // const socket = io(config.baseURL);
  // const socket = io(config.baseURL, { secure: true });
  const { socket } = useChainContext()


  const fetchChatData = () => {
    setIsLoading(true);
    const payload = {
      type: "admin",
      limit: limit,
      page_number: page,
      user_id: JSON.parse(sessionStorage.getItem("user")).data.user.user_id,
    };

    getChat(payload)
      .then((data) => {
        setIsLoading(true);
        //////console.log('Chat data:', data);
        const dta = data.data.chats;

        const privateChats = dta.filter(
          (chat) => chat.type === "private" && chat.support_user_id === adminId
        );
        const roomChats = dta.filter((chat) => chat.type !== "private" && chat.support_user_id !== adminId && (chat.type === "room" || chat.type === "otc-room"));

        // Filter user chats where support_user_id is not equal to user_id
        const userChats = dta.filter(
          (chat) =>
            chat.support_user_id !==
            JSON.parse(sessionStorage.getItem("user")).data.user.user_id
        );

        setMessagesArray([]);
        setChatData(privateChats);
        setroomData(roomChats);
        // Store user chats in userChats state
        setUserChats(userChats);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);

        console.error("Error fetching chat data:", error);
      });
  };
  useEffect(() => {
    fetchChatData();
  }, [openContacts])

  useEffect(() => {
    // const socket = io(config.baseURL, { secure: true });
    // socket.on("connect", () => {
    //   socket.on("connection", () => {
    //     // Handle connection to the server
    //   });

    //   // Emit a 'connected' event to the server with specific user data
    //   socket.emit(
    //     "connected",
    //     {
    //       subject: "testing",
    //       user_id: JSON.parse(sessionStorage.getItem("user")).data.user.user_id,
    //       user_id_2: UserId,
    //     },
    //     (response) => {
    //       console.log("🚀 ~ socket.on ~ response:", response)
    //       // Handle the response from the server after connection
    //     }
    //   );
    // });

    fetchChatData();

    if (!socket) {
      return false
    }
    socket.on("message", (newMessage) => {
      console.log("🚀 ~ socket.on ~ newMessage:", newMessage)
      const chatId = newMessage.id;
      const foundChat = chatData.find((chat) => chat._id === chatId);

      if (newMessage.type === "user" || newMessage.type === "private") {
        const matchingChat = chatData.find((chat) => chat._id === newMessage.id);
        if (matchingChat) {
          matchingChat?.messages?.push(newMessage);
        } else {
          // console.log(`Matching ${newMessage.type} chat not found for message with ID: ${chatId}`);
        }
      } else if (newMessage.type === "room") {
        const matchingChat = roomData.find((chat) => chat._id === newMessage.id);
        if (matchingChat) {
          matchingChat.messages.push(newMessage);
        } else {
          // console.log(`Matching room chat not found for message with ID: ${chatId}`);
        }
      }
    });


    socket.on("disconnect", () => {
      // Handle disconnection from the server
    });

    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });

    // Fetch chat data from the server
    const payload = {
      type: "admin",
      limit: limit,
      page_number: page,
      user_id: JSON.parse(sessionStorage.getItem("user")).data.user.user_id,
    };

    getChat(payload)
      .then((data) => {
        setIsLoading(true);
        const dta = data.data.chats;
        const privateChats = dta.filter(
          (chat) => chat.type === "private" || chat.type == "user" && chat.support_user_id === adminId
        );
        const roomChats = dta.filter((chat) => chat.type !== "private" && chat.support_user_id !== adminId && (chat.type === "room" || chat.type === "otc-room"));
        // Filter user chats where support_user_id is not equal to user_id
        const userChats = dta.filter(
          (chat) =>
            chat.type !== "private" && chat.type !== "user" && chat.type !== "room" && chat.type !== "otc-room"
        );
        setMessagesArray([]);
        setChatData(privateChats);
        setroomData(roomChats);
        setUserChats(userChats);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching chat data:", error);
      });


    socket.on("new_chat", (response) => {
      console.log('new_chat response:', response);

      // Handle new chat data
      if (Array.isArray(response)) {
        response.forEach((newObj) => {
          // console.log('newObj response:', newObj);
          // console.log('newObj.type response:', newObj.type);

          if (newObj && newObj.type) {
            if (newObj.type === "room") {
              setroomData((prevroomData) => [...prevroomData, newObj]);
            } else if (newObj.type === "private") {
              setChatData((prevChatData) => [...prevChatData, newObj]);
            } else {
              // console.log('Invalid type for newObj:', newObj.type);
            }
          } else {
            // console.log('Invalid response object:', newObj);
          }
        });
      } else {
        // console.log('Invalid response, not an array:', response);
      }
    });


  }, [socket]);

  const sendMessage = (message) => {
    //////console.log('Message sent', message);
    setNewMessage(message);

    if (currentChat1.type === "room") {
      socket.emit(
        "sendGroupMessage",
        {
          room_id: ChatId,
          sender_id: JSON.parse(sessionStorage.getItem("user")).data.user
            .user_id,
          message: message,
        },
        (response) => {
          //////console.log('Group Message sent, server response:', response);
        }
      );
    } else {
      socket.emit(
        "sendMessage",
        {
          chat_id: ChatId,
          sender_id: JSON.parse(sessionStorage.getItem("user")).data.user
            .user_id,
          message: message,
        },
        (response) => {
          //////console.log('Message sent, server response:', response);
        }
      );
    }
  };

  // const onLoadMoreClick = () => {
  //   setLimit(previousLimit => previousLimit + increment); // Update limit
  //   setPage(previousPage => previousPage + 1); // Update page number
  //   getChat({ limit: limit + increment, page: page + 1 }); // Call getChat with updated limit and page
  // };

  const onOpenChat = (item) => {
    //////console.log('onOpenChat contact', item);
    setCurrentChat(item?._id);
    setCurrentChat1(item);
    setShowCurrentChat(item);
    setselectedChatId(item?._id);
    setChatId(item?._id);
    fetchChats(item?._id);
    setUserId(item?.user_id);
    setUserName(item?.user_detail[0]?.name);

    // Assuming ShowcurrentChat is defined and accessible here
  };
  useEffect(() => {
    setChatMessage([]);
    setMessagesArray([]);
    setCurrentChat(null); // Reset the current chat to empty or null
    setSelectedChat(false);
  }, [currentView]);

  useEffect(() => {
    if (NewMessage?.length > 0) {
      const updatedMessage = { message: NewMessage };
      setMessagesArray((prevMessages) => [...prevMessages, updatedMessage]);
    }
  }, [NewMessage]);

  const fetchChats = async (ChatId) => {
    //////console.log('cccccc', ChatId);
    setMessagesArray([]);
    try {
      setIsLoading(true);
      const requestData = {
        chat_id: ChatId,
      };
      const response = await getChatMessages(requestData);
      if (response?.data?.chat_reply) {
        const flashProductsData = response?.data?.chat_reply;
        setChatMessage(flashProductsData);
        setIsLoading(false);
        setMessagesArray([]);
      } else {
        setIsLoading(false);
        console.error("Error fetching tickets:", response?.statusText);
      }
    } catch (error) {
      setIsLoading(false);
      setChatMessage([]);
      // setIsLoading(false);
      console.error("Error fetching tickets:", error);
    }
  };

  const onOpenContacts = () => {
    setOpenContacts((state) => !state);
  };
  useEffect(() => {
    //////console.log('ShowcurrentChat', ShowcurrentChat)
    if (
      ShowcurrentChat &&
      ShowcurrentChat?.status === "open" &&
      ShowcurrentChat?.support_user_id === adminId
    ) {
      setSelectedChat(true);
    } else {
      setSelectedChat(false);
    }
  }, [ShowcurrentChat]);
  // const roomData = []; // Your room data
  // const currentChat = '';

  const displayData =
    currentView === "chats"
      ? chatData
      : currentView === "rooms"
        ? roomData
        : userChats;

  const handleSearch = (searchTerm) => {
    // console.log("searchTerm", searchTerm);
    let filteredChats = [];

    switch (currentView) {
      case "chats":
        filteredChats = chatData.filter((chat) =>
          chat?.user_detail[0]?.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        );
        setChatData(filteredChats);
        break;

      case "rooms":
        filteredChats = roomData.filter((room) =>
          room?.subject.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setroomData(filteredChats);
        break;

      case "userChats":
        filteredChats = userChats.filter((userChat) =>
          userChat?.user_detail[0]?.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        );
        setUserChats(filteredChats);
        break;

      default:
        return displayData;
    }

    if (searchTerm.trim() === "") {
      // console.log("searchTerm emptyyyyyyyyyyyyyyy");
      // console.log("searchTerm currentView", currentView, chatData);
      fetchChatData();
      // If the search term is empty, display all the data based on the current view
      switch (currentView) {
        case "chats":
          setFilteredData(chatData);
          // console.log("switch vcv");
          break;

        case "rooms":
          setFilteredData(roomData);
          break;

        case "userChats":
          setFilteredData(userChats);
          break;

        default:
          setFilteredData(displayData);
          break;
      }
    } else {
      setFilteredData(filteredChats);
    }
  };

  //////console.log('displayData displayData ', displayData)
  const user = JSON.parse(sessionStorage.getItem("user")).data.user;

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          // background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>

      <ChatContainer
        open={openContacts}
        onClick={openContacts ? onOpenContacts : null}
        role="presentation"
      >
        <ChatContactList open={openContacts}>
          <ChatSearch onSearch={handleSearch} />
          <div
            className="flex justify-around p-1 border-2 border-indigo-600 "
            style={{ overflow: "hidden" }}
          >
            <div
              style={{ color: currentView === "chats" ? "darkred" : "white" }}
              onClick={() => setCurrentView("chats")}
            >
              <button
                type="button"
                className={`${currentView === "chats" ? "text-yellow-600" : ""
                  }`}
              >
                Chats
              </button>
            </div>
            <div
              style={{ color: currentView === "rooms" ? "darkred" : "white" }}
              onClick={() => setCurrentView("rooms")}
            >
              <button
                type="button"
                className={`${currentView === "rooms" ? "text-yellow-600" : ""
                  }`}
              >
                Rooms
              </button>
            </div>
            <div
              style={{
                color: currentView === "userChats" ? "darkred" : "white",
              }}
              onClick={() => setCurrentView("userChats")}
            >
              <button
                type="button"
                className={`${currentView === "userChats" ? "text-yellow-600" : ""
                  }`}
              >
                User Chats
              </button>
            </div>
          </div>
          <ChatContacts style={{ overflow: "hidden" }}>
            <ChatContactsScroll alwaysShowTracks style={{ overflow: "hidden" }}>
              {displayData && displayData?.length > 0 ? (
                displayData.map((item) => (
                  <ChatContactButton
                    key={item?._id}
                    type="button"
                    onClick={(e) => onOpenChat(item)}
                  >
                    <ChatContact
                      active={item?._id === currentChat}
                      currentView={currentView}
                      contact={item}
                    />
                  </ChatContactButton>
                ))
              ) : (
                <ChatEmptyMessages>
                  {" "}
                  <p style={{ color: "white", marginTop: "20px" }}>
                    No {currentView === "chats" ? "chats" : "rooms"} available.
                  </p>{" "}
                </ChatEmptyMessages>
              )}
            </ChatContactsScroll>
          </ChatContacts>
        </ChatContactList>
        {currentChat === null ? (
          <ChatDialog>
            <ChatTopbar
              ChatId={ChatId}
              onClick={onOpenContacts}
              setSelectedChat={setSelectedChat}
            />
            <ChatEmptyMessages>
              <p>Select a chat to start messaging</p>
            </ChatEmptyMessages>
          </ChatDialog>
        ) : (
          <ChatDialog>
            <ChatTopbar
              contact={
                displayData &&
                displayData.find((item) => item._id === currentChat)
              }
              ChatId={ChatId}
              onClick={onOpenContacts}
            />
            <ChatScroll alwaysShowTracks>
              <ChatMessagesWrap>
                {currentMessages === null ||
                  !Array.isArray(currentMessages) ||
                  currentMessages?.length === 0 ? (
                  <ChatMessages>
                    <ChatEmptyMessages>
                      <p>No messagesaaaa</p>
                    </ChatEmptyMessages>
                  </ChatMessages>
                ) : (
                  <ChatMessages>
                    {(ChatMessage && ChatMessage?.length > 0) ||
                      (messagesArray && messagesArray?.length > 0) ? (
                      ChatMessage.map((item) => (
                        <ChatBubble
                          key={item?._id}
                          message={item}
                          date={item} // Ensure this is the date property from 'item'
                          active={item?.chat_id === currentUser?._id}
                        />
                      ))
                    ) : (
                      // If both ChatMessage and message arrays are empty or non-existent
                      <ChatEmptyMessages>
                        <p>No messages.</p>
                      </ChatEmptyMessages>
                    )}
                    {selectedChatId &&
                      messagesArray.map((message, index) => (
                        <ChatBubbleMessageWrap
                          key={index}
                          style={
                            message?.type === "room" ||
                              message?.type === "private"
                              ? {
                                marginright: "auto",
                                borderRadius: "0 20px 20px 20px",
                                padding: "15px 15px 10px 12px",
                                paddingTop: "7px",
                                marginBottom: "20px",
                                // background:'rned',
                                paddingBottom: "4px",
                              }
                              : {
                                marginBottom: "20px",
                                // background: 'rgba(45,49,57,1)',
                                marginLeft: "auto",
                                borderRadius: "15px 0px 0px 15px",
                                padding: "10px 10px 5px 15px",
                                paddingTop: "7px",
                                paddingBottom: "4px",
                                // width:'158px'
                              }
                          }
                        >
                          {message?.type === "room" ||
                            message?.type === "private" ? (
                            <ChatBubbleContactName
                              style={{ textTransform: "capitalize" }}
                            >
                              {message?.user?.name}
                            </ChatBubbleContactName>
                          ) : (
                            <ChatBubbleContactName
                              style={{ textTransform: "capitalize" }}
                            >
                              {`${user?.firstName} `}
                            </ChatBubbleContactName>
                          )}

                          {message?.type === "room" ||
                            message?.type === "private" ? (
                            <ChatBubbleMessage style={{ margin: "0 0 5px 0" }}>
                              {message.message &&
                                typeof message.message === "string"
                                ? message.message
                                : null}
                            </ChatBubbleMessage>
                          ) : (
                            <ChatBubbleMessage style={{ margin: "0 0 5px 0" }}>
                              {message?.message &&
                                typeof message.message === "string"
                                ? message.message
                                : null}
                            </ChatBubbleMessage>
                          )}
                          {message?.Files && message?.Files?.length > 0 && (
                            <div>
                              {message?.Files?.map((file, index) => (
                                <a
                                  key={index}
                                  href={file}
                                  target="_blank"
                                  className="ml-4"
                                  rel="noopener noreferrer"
                                  download
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    size="1x"
                                    className="my-icon"
                                  />
                                </a>
                              ))}
                            </div>
                          )}
                          <ChatBubbleDate>{formattedTime}</ChatBubbleDate>
                        </ChatBubbleMessageWrap>
                      ))}
                  </ChatMessages>
                )}
              </ChatMessagesWrap>
            </ChatScroll>
            {selectedChat && <ChatField onSendMessage={sendMessage} />}
            {/* <ChatField onSendMessage={sendMessage} /> */}
          </ChatDialog>
        )}
      </ChatContainer>
    </>
  );
};

Chat.propTypes = {
  currentUser: CurrentUserProps.isRequired,
  contacts: ContactsProps.isRequired,
};

export default Chat;

// region STYLES

const ChatBubbleMessageWrap = styled.div`
  max-width: 270px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0 40px 40px 0;

  ${paddingRight}: 40px;
  ${paddingLeft}: 20px;
  background-color: ${colorBubble};
  ${(props) => props.file && "padding: 10px 20px 20px 20px"};

  img {
    max-width: 215px;
  }
`;

const ChatBubbleContactName = styled.p`
  margin-top: 0;
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 500;
  color: ${colorAccent};
  ${paddingRight}: 50px;
`;

const ChatBubbleMessage = styled.p`
  margin: 0 0 10px 0;
  font-size: 12px;
  color: ${colorText};
`;

const ChatBubbleDate = styled.p`
  color: ${colorAdditional};
  font-size: 10px;
  margin: 0;
  text-align: end;
  ${right}: 36px;

  @media screen and (min-width: 1366px) {
    ${right}: 0;
  }
`;

const ChatContainer = styled.div`
  height: calc(100vh - 185px);
  min-height: 400px;
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background-color: ${colorBackground};

  @media screen and (max-width: 1023px) {
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      ${right}: 0;
      left: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 0.3s, z-index 0.3s 0.3s;
      background-color: ${colorAdditional};
    }

    ${(props) =>
    props.open &&
    `
      &:before {
        opacity: 0.2;
        z-index: 1;
        transition: opacity 0.3s, z-index 0s;
        pointer-events: none;
      }

      .chat__scroll, .chat__text-field {
        pointer-events: none;
      }
    `}
  }
`;

const ChatContactList = styled.div`
  width: 335px;
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s;
  background-color: ${colorBackground};
  ${borderRight}: 1px solid ${colorFieldsBorder};

  @media screen and (max-width: 1023px) {
    max-width: 335px;
    width: 100%;
    transform: translateX(calc(-100% - 1px));
    position: absolute;
    z-index: 1;

    ${(props) =>
    props.open &&
    `
      transform: translateX(0);
      ${borderRight(props)}: none;
    `}
  }
`;

const ChatContacts = styled.div`
  overflow: auto;
  height: calc(100% - 55px);
`;

const ChatContactButton = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  background: transparent;
  text-align: ${left};
`;

const ChatDialog = styled.div`
  width: calc(100% - 335px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
`;

const ChatMessagesWrap = styled.div`
  width: 100%;
  display: flex;
`;

const ChatMessages = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`;

const ChatEmptyMessages = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;

  p {
    margin: auto;
    border-radius: 15px;
    padding: 5px 20px;
    color: ${colorAdditional};
    background-color: ${colorBackgroundBody};
  }
`;

const ChatScroll = styled(Scrollbar)`
  text-align: ${left};

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      ${marginRight}: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;

const ChatContactsScroll = styled(Scrollbar)`
  height: 100%;

  .scroll-content {
    &,
    & > div {
      height: 100%;
    }
  }

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      ${marginRight}: 3px;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`;
// endregion
