import Modal from "react-bootstrap/Modal";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  createOtcMarketPlace,
  getAllTokens,
  getApprovers,
  getToken,
} from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
const CreateOtcMarketplaceModal = ({ show, hide, reFetch }) => {
  const [searchUserId, setSearchUserId] = useState([]);
  const [inputId, setInputId] = useState("");
  const [userName, setUserName] = useState("");
  const [searchLoad, setsearchLoad] = useState(false);
  const [marketplaceName, setMarketplaceName] = useState("");
  const [buyPackageLimit, setBuyPackageLimit] = useState("");
  const [pool1, setPool1] = useState("");
  const [pool2, setPool2] = useState("");
  const [fromContractAddress, setFromContractAddress] = useState("");
  const [toContractAddress, setToContractAddress] = useState("");
  const [swapingStatus, setSwapingStatus] = useState("active");
  const [buySellStatus, setBuySellStatus] = useState("active");
  const [isLoading, setIsLoading] = useState(false);
  const [chain_id, setChain_id] = useState(null);
  const [swapFee, setSwapFee] = useState(null);
  const [buyFee, setBuyFee] = useState(null);
  const [transferFee, setTransfer] = useState(null);
  const [swapFeeType, setSwapFeeType] = useState("");
  const [transferFeeType, setTransferFeeType] = useState("");
  const [buyfeeType, setBuyFeeType] = useState("");
  const [buyStatus, setBuyStatus] = useState("active");
  const [transferStatus, setTransferStatus] = useState("active");
  const [packageChoice, setPackageChoice] = useState("active");
  const [refferenceCode, setRefferenceCode] = useState("");
  const [taxSymbol, setTaxSymbol] = useState(null);
  const [allTokens, setAllTokens] = useState([]);
  const [allTokensPool2, setAllTokensPool2] = useState([]);

  const [selectedToken, setSelectedToken] = useState({
    symbol: "",
    price: "",
  });
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [checkedTokens, setCheckedTokens] = useState({});

  console.log(
    "🚀 ~ CreateOtcMarketplaceModal ~ selectedTokens:",
    selectedTokens
  );
  // Function to handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedCheckedTokens = {
      ...checkedTokens,
      [index]: !checkedTokens[index], // Toggle the checkbox state
    };
    setCheckedTokens(updatedCheckedTokens);

    // If unchecked, remove the token from selectedTokens
    if (!updatedCheckedTokens[index]) {
      setSelectedTokens((prevTokens) =>
        prevTokens.filter((token) => token.symbol !== allTokens[index].value)
      );
    }
  };

  // Function to handle price changes
  const handlePriceChange = (index, e) => {
    const { value } = e.target;
    const updatedTokens = [...selectedTokens];

    // Check if the token is already in the selectedTokens array
    const existingToken = updatedTokens.find(
      (token) => token.symbol === allTokens[index].value
    );

    if (existingToken) {
      // Update price if the token is already selected
      existingToken.price = value;
    } else {
      // Add the new token with price if it's checked
      if (checkedTokens[index]) {
        updatedTokens.push({ symbol: allTokens[index].value, price: value });
      }
    }

    setSelectedTokens(updatedTokens); // Update state with the modified array
  };

  const handleInputChange = (name) => {
    setsearchLoad(true);
    if (name.length >= 2) {
      getApprovers({ name })
        .then((res) => {
          setsearchLoad(false);
          const arr = res.data.user.map((i) => ({
            id: i._id,
            address: i.address,
            value: i.name,
            label: i.name,
            sol_address: i?.sol_address,
          }));

          setsearchLoad(false);

          // Case-insensitive comparison
          const matchingOptions = arr.filter(
            (option) => option.value.toLowerCase() === name.toLowerCase()
          );

          setSearchUserId(matchingOptions);
        })
        .catch((error) => {
          setsearchLoad(false);
          // setstatusCode(400);

          if (error.message === "Network Error") {
            // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
          } else {
          }
        });
    } else {
      setSearchUserId([]); // Clear options when input length is less than 2
    }

    setsearchLoad(false);
  };
  const types = [
    { value: "percentage", label: "Percentage" },
    { value: "dollar", label: "USDT" },
  ];
  const packageChoiceOptions = [
    { value: "virtual_transfer", label: "Virtual Transfer" },
    { value: "open_contract", label: "Open Contract" },
  ];

  const CreateNewOtcMarketplace = async () => {
    try {
      setIsLoading(true);
      if (
        !marketplaceName ||
        !inputId ||
        !pool1 ||
        !pool2 ||
        !buySellStatus ||
        !swapingStatus ||
        !swapFeeType ||
        !transferFeeType ||
        !buyfeeType ||
        !buyStatus ||
        !packageChoice ||
        !taxSymbol ||
        !buyPackageLimit
      ) {
        // If any required field is missing,  show error toast
        toast.error("Please fill in all required fields.");
        setIsLoading(false);
        return;
      }
      const poolVal = [pool1, pool2].join("-");
      const payload = {
        market_place_name: marketplaceName,
        owner_id: inputId,
        pool: poolVal,
        swapping_status: swapingStatus,
        contract_address_pool_one: fromContractAddress,
        contract_address_pool_two: toContractAddress,
        buy_fee: buyFee,
        transfer_fee: transferFee,
        swap_fee: swapFee,
        swap_fee_type: swapFeeType,
        transfer_fee_type: transferFeeType,
        buy_fee_type: buyfeeType,
        transfer_status: transferStatus,
        buy_status: buyStatus,
        package_choice: packageChoice,
        swap_pairs: selectedTokens,
        tax_token_symbol: taxSymbol,
        // refference_code: refferenceCode,
        buy_package_user_limit: buyPackageLimit,
      };
      const res = await createOtcMarketPlace(payload);
      toast.success(res.data.Message);
      setIsLoading(false);
      reFetch();
      hide();
    } catch (error) {
      console.log("🚀 ~ CreateNewOtcMarketplace ~ error:", error);
      // Handle error
      toast.error(error?.response?.data.Message.message || error);
      setIsLoading(false);
    }
  };

  const fetchAllTokens = async () => {
    const payload = {
      chain_id: chain_id,
    };
    const res = await getToken();
    console.log("🚀 ~ fetchAllTokens ~ res:", res);
    setAllTokens(res.data.user);
    const filteredData = res.data.user.filter((item) => item.symbol !== "BTC");
    setAllTokensPool2(filteredData);
  };
  useEffect(() => {
    fetchAllTokens();
  }, [chain_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fees = await getToken();
        const data = fees?.data?.user;
        const modifiedResponse = data.map((item) => ({
          label: item.symbol,
          value: item.symbol,
        }));
        setAllTokens(modifiedResponse);
        const filteredData = modifiedResponse.filter(
          (item) => item.label !== "BTC"
        );
        setAllTokensPool2(filteredData);
      } catch (error) {
        console.error("Error fetching fees:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>

        <Modal.Title
          className="p-4 flex flex-row justify-between"
          style={{ fontSize: "large" }}
        >
          <b>Create New OTC Marketplace </b>
          <i
            class="fa fa-times cursor-pointer"
            aria-hidden="true"
            onClick={hide}
          ></i>
        </Modal.Title>

        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div
              className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className=" flex flex-wrap justify-start gap-4 items-start">
                <div className="flex flex-col col-12 col-md-3 ">
                  <label>Marketplace Name</label>
                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      value={marketplaceName}
                      className="form-control "
                      placeholder="Marketplace Name"
                      onChange={(e) => setMarketplaceName(e.target.value)}
                    />
                  </InputDiv>
                </div>

                <div className="flex flex-col col-12 col-md-3 ">
                  <label>Buy Fee</label>

                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      value={buyFee}
                      className="form-control "
                      placeholder="Buy Fee"
                      onChange={(e) => setBuyFee(e.target.value)}
                    />
                  </InputDiv>
                </div>
                <div className="flex flex-col col-12 col-md-3 ">
                  <label>Transfer Fee</label>

                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      value={transferFee}
                      className="form-control "
                      placeholder="Transfer Fee"
                      onChange={(e) => setTransfer(e.target.value)}
                    />
                  </InputDiv>
                </div>
                <div className="flex flex-col col-12 col-md-3 ">
                  <label>Swap Fee</label>

                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      value={swapFee}
                      className="form-control "
                      placeholder="Swap Fee"
                      onChange={(e) => setSwapFee(e.target.value)}
                    />
                  </InputDiv>
                </div>

                <div className="flex flex-col col-12 col-md-3 ">
                  <label>Buy Package Limit</label>

                  <InputDiv>
                    <ChatSearchInput
                      type="number"
                      value={buyPackageLimit}
                      className="form-control "
                      placeholder="Enter Buy package limit"
                      onChange={(e) => setBuyPackageLimit(e.target.value)}
                    />
                  </InputDiv>
                </div>

                <div className="col-12 col-md-3 ">
                  <label>Owner Name</label>
                  <InputDiv>
                    <DropdownDiv className="mb-0">
                      <CustomSelect
                        isLoading={searchLoad}
                        name="receiver"
                        onInputChange={handleInputChange}
                        placeholder={`${userName || "Owner Name"}`}
                        onChange={(value) => {
                          console.log(
                            "🚀 ~ CreateOtcMarketplaceModal ~ value:",
                            value
                          );

                          setUserName(value?.label);
                          setInputId(value?.id);
                        }}
                        value={userName}
                        options={searchUserId}
                        isSearchable
                      />
                    </DropdownDiv>
                  </InputDiv>
                </div>

                <div className=" flex flex-col col-12 col-md-3 ">
                  <label>Sold Symbol</label>
                  <InputDiv>
                    <DropdownDiv className="mb-0">
                      <CustomSelect
                        isLoading={searchLoad}
                        name="receiver"
                        placeholder={`${pool1 ? pool1 : "Sold Symbol"}`}
                        onChange={(value) => {
                          setFromContractAddress(value.value);
                          setPool1(value.label);
                        }}
                        value={pool1}
                        options={allTokensPool2}
                        isSearchable
                      />
                    </DropdownDiv>
                  </InputDiv>
                </div>
                <div className=" flex flex-col col-12 col-md-3 ">
                  <label>Buy Symbol</label>
                  <InputDiv>
                    <DropdownDiv className="mb-0">
                      <CustomSelect
                        isLoading={searchLoad}
                        name="receiver"
                        // onInputChange={handleInputChange}
                        placeholder={`${pool2 ? pool2 : "Buy Symbol"}`}
                        onChange={(value) => {
                          setToContractAddress(value.value);
                          setPool2(value.label);
                        }}
                        value={pool2}
                        options={allTokens}
                        isSearchable
                      />
                    </DropdownDiv>
                  </InputDiv>
                </div>
                <div className="col-12 col-md-3 ">
                  <label>Swap Fee Type</label>
                  <InputDiv>
                    <DropdownDiv className="mb-0">
                      <CustomSelect
                        isLoading={searchLoad}
                        name="receiver"
                        placeholder={`Swap Fee Type`}
                        onChange={(value) => setSwapFeeType(value.value)}
                        value={swapFeeType}
                        options={types}
                        isSearchable
                      />
                    </DropdownDiv>
                  </InputDiv>
                </div>

                <div className="col-12 col-md-3 ">
                  <label>Buy Fee Type</label>
                  <InputDiv>
                    <DropdownDiv className="mb-0">
                      <CustomSelect
                        isLoading={searchLoad}
                        name="receiver"
                        placeholder={`Buy Fee Type`}
                        onChange={(value) => setBuyFeeType(value.value)}
                        value={buyfeeType}
                        options={types}
                        isSearchable
                      />
                    </DropdownDiv>
                  </InputDiv>
                </div>
                <div className="col-12 col-md-3 ">
                  <label>Transfer Fee Type</label>

                  <InputDiv>
                    <DropdownDiv className="mb-0">
                      <CustomSelect
                        isLoading={searchLoad}
                        name="receiver"
                        placeholder={`Transfer Fee Type`}
                        onChange={(value) => setTransferFeeType(value.value)}
                        value={transferFeeType}
                        options={types}
                        isSearchable
                      />
                    </DropdownDiv>
                  </InputDiv>
                </div>
                <div className="col-12 col-md-3 ">
                  <label>Package Choice</label>

                  <InputDiv>
                    <DropdownDiv className="mb-0">
                      <CustomSelect
                        isLoading={searchLoad}
                        name="receiver"
                        placeholder={`Package Choice`}
                        onChange={(value) => setPackageChoice(value.value)}
                        value={packageChoice}
                        options={packageChoiceOptions}
                        isSearchable
                      />
                    </DropdownDiv>
                  </InputDiv>
                </div>
                <div className="col-12 col-md-3 ">
                  <label>Select tax symbol</label>

                  <InputDiv>
                    <DropdownDiv className="mb-0">
                      <CustomSelect
                        isLoading={searchLoad}
                        name="receiver"
                        placeholder={`${taxSymbol || "Select Tax Symbol"}`}
                        onChange={(value) => setTaxSymbol(value.label)}
                        value={taxSymbol}
                        options={allTokens}
                        isSearchable
                      />
                    </DropdownDiv>
                  </InputDiv>
                </div>

                <div className=" md:flex gap-4">
                  <div className=" inline-block text-black col-12 col-md-4 ">
                    <label>Swaping Status</label>
                    <select
                      value={swapingStatus}
                      onChange={(event) => setSwapingStatus(event.target.value)}
                      className="appearance-none w-full bg-white text-stone-950 border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                  <div className=" inline-block text-black col-12 col-md-4 ">
                    <label>Buy Status</label>
                    <select
                      value={buyStatus}
                      onChange={(event) => setBuyStatus(event.target.value)}
                      className="appearance-none w-full bg-white text-stone-950 border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                  <div className=" inline-block text-black col-12 col-md-4 ">
                    <label>Transfer Status</label>
                    <select
                      value={transferStatus}
                      onChange={(event) =>
                        setTransferStatus(event.target.value)
                      }
                      className="appearance-none w-full bg-white text-stone-950 border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
                {allTokens.map((token, index) => (
                  <div
                    key={token.value}
                    className="flex items-center justify-between bg-white p-4 rounded-md shadow-md"
                  >
                    {/* Left side: Switch */}
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        id={`switch-${token.value}`}
                        checked={checkedTokens[index] || false} // Check if checkbox is selected
                        onChange={() => handleCheckboxChange(index)} // Handle checkbox change
                      />
                      <label
                        htmlFor={`switch-${token.value}`}
                        className="sr-only"
                      >
                        Enable
                      </label>
                    </div>

                    {/* Middle: Coin Name */}
                    <div className="flex-1 text-center">
                      <p className="font-medium text-gray-700">{token.label}</p>
                    </div>

                    {/* Right side: Price input */}
                    <div>
                      <input
                        type="number"
                        placeholder="Price"
                        className="w-24 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        disabled={!checkedTokens[index]} // Disable input if checkbox is unchecked
                        onChange={(e) => handlePriceChange(index, e)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className=" flex justify-center items-center">
                <button
                  className="text-white p-1 px-2 py-2 rounded-md bg-primary "
                  onClick={CreateNewOtcMarketplace}
                >
                  Create OTC Marketplace
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CreateOtcMarketplaceModal;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
