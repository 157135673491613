import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { CustomSelect } from "../../shared/components/CustomSelect";
import { getApprover, getToken, getUserBalanceInternalWallet, transferInternalBalance } from "../../utils/services/user.service";
import { Button } from "../../shared/components/Button";

const TransferInternalBalanceModal = ({ show, hide, reFetch }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchAllUsers, setSearchAllUsers] = useState([]);
    const [toUserId, setToUserId] = useState("")
    const [fromUserId, setFromUserId] = useState("")
    const [toUserName, setToUserName] = useState("")
    const [fromUserName, setFromUserName] = useState("")
    const [searchLoad, setSearchLoad] = useState(false)
    const [amount, setAmount] = useState("")
    const [symbol, setSymbol] = useState("")
    const [allSymbols, setAllSymbols] = useState([]);
    const [filteredsymbols, setfilteredSymbols] = useState([])
    const [totalCoinBalance, setTotalCoinsBalance] = useState(null)

    const SmallLabel = ({ text, smallText }) => (
        <span>
            {text}{" "}
            <small>
                <b>{smallText}</b>
            </small>
        </span>
    );
    useEffect(() => {
        const defaultOptions = allSymbols
            ?.filter(item => item.symbol !== 'BTC') // Filter out the symbol 'BTC'
            .map((item) => ({
                value: item?.symbol,
                label: <SmallLabel text={item?.symbol === "META" ? "MET$" : item?.symbol} smallText={item?.quantity} />,
                price: item?.price,
                chain_id: item?.chain_id
            }));

        setfilteredSymbols(defaultOptions);
    }, [allSymbols]);


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const res = await getUserBalanceInternalWallet(fromUserId);
                const data = res?.data?.data;
                setAllSymbols(data);
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
            }
        };

        if (fromUserId) {

            fetchData();
        }
    }, [fromUserId]);
    const handleInputChange = (name) => {

        setSearchLoad(true);

        if (name.length >= 2) {
            getApprover({ name })
                .then((res) => {
                    const arr = res.data.user.map((i) => ({
                        id: i._id,
                        address: i.address,
                        value: i.name,
                        label: i.name,
                        sol_address: i?.sol_address,
                        onChainAddress: i?.secondary_wallet_address,
                        onChainSolAddress: i?.secondary_wallet_sol_address,
                        striga_user_id: i?.striga_user_id,
                        trcWalletAddress: i?.trc_address,
                    }));

                    // setsearchLoad(false);
                    // Case-insensitive comparison
                    const matchingOptions = arr.filter(
                        (option) => option.value.toLowerCase() === name.toLowerCase()
                    );
                    // setDefaultBenificiary(matchingOptions[0]?.address)
                    // setRecieverSolAddress(matchingOptions[0]?.sol_address)
                    // setOnchainWalletAddress(matchingOptions[0])
                    // setRecieverTrcAddress(matchingOptions[0]?.trcWalletAddress)

                    if (matchingOptions.length > 0) {
                        setSearchAllUsers(matchingOptions);
                        setSearchLoad(false)
                    }
                    // setSearchLoad(false)
                })
                .catch((error) => {
                    // setsearchLoad(false);
                    setSearchLoad(false)

                });
        } else {
            setSearchAllUsers([]); // Clear options when input length is less than 2
            setSearchLoad(false)
        }

        // setsearchLoad(false);
    };

    const handleConfirm = async () => {
        setIsLoading(true)
        try {
            const payload = {
                symbol: symbol, from_user_id: fromUserId, to_user_id: toUserId, amount: amount
            }
            const res = await transferInternalBalance(payload)
            toast.success(res?.data?.Message)
            reFetch()
            setIsLoading(false)
            hide()
        } catch (error) {
            toast.error(error.response.data.Message)
            setIsLoading(false)
            hide()

        }
    }
    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={hide}
            >
                <div
                    className="loader-overlay"
                    style={{
                        display: isLoading ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        zIndex: 99999,
                    }}
                >
                    <Ring size={65} color="#00897B" />
                </div>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        <b>Transfer Internal Balance </b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container padding-bottom-3x p-0">
                        <div
                            className=" mt-0 p-0 flex flex-col gap-8 "
                            style={{ boxShadow: "none", fontFamily: "sans-serif" }}
                        >
                            <Row>
                                <Col md={4}>
                                    <InputDiv>
                                        <label
                                            style={{ fontSize: "small" }}
                                            htmlFor="features"
                                            className="form-label text-black mb-1"
                                        >
                                            From User
                                        </label>
                                        <CustomSelect
                                            isLoading={searchLoad}
                                            name="receiver"
                                            onInputChange={handleInputChange}
                                            placeholder={
                                                fromUserName || "From user"
                                            }
                                            onChange={(value) => {
                                                console.log("🚀 ~ TransferInternalBalanceModal ~ value:", value)
                                                setFromUserId(value.id)
                                                setFromUserName(value?.value)
                                            }}
                                            value={fromUserName}
                                            options={searchAllUsers}
                                            isSearchable
                                        />
                                    </InputDiv>
                                </Col>
                                <Col md={4}>
                                    <InputDiv>
                                        <label
                                            style={{ fontSize: "small" }}
                                            htmlFor="features"
                                            className="form-label text-black mb-1"
                                        >
                                            To User
                                        </label>
                                        <CustomSelect
                                            isLoading={searchLoad}
                                            name="receiver"
                                            onInputChange={handleInputChange}
                                            placeholder={
                                                toUserName || "To user"
                                            }
                                            onChange={(value) => {
                                                console.log("🚀 ~ TransferInternalBalanceModal ~ value:", value)
                                                setToUserId(value.id)
                                                setToUserName(value.value)
                                            }}
                                            value={toUserName}
                                            options={searchAllUsers}
                                            isSearchable
                                        />
                                    </InputDiv>
                                </Col>

                                {fromUserId && <Col md={4}>
                                    <InputDiv>
                                        <label
                                            style={{ fontSize: "small" }}
                                            htmlFor="features"
                                            className="form-label text-black mb-1"
                                        >
                                            Symbol
                                        </label>
                                        <CustomSelect
                                            // isLoading={searchLoad}
                                            name="receiver"
                                            placeholder={
                                                "Select Symbol"
                                            }
                                            onChange={(value) => {
                                                console.log("🚀 ~ TransferInternalBalanceModal ~ value:", value)
                                                setSymbol(value.value)
                                                setTotalCoinsBalance(value?.label?.props?.smallText)
                                            }}
                                            value={symbol}
                                            options={filteredsymbols}
                                            isSearchable
                                        />
                                    </InputDiv>
                                </Col>}
                                <Col md={4}>
                                    <InputDiv>
                                        <label
                                            style={{ fontSize: "small" }}
                                            htmlFor="features"
                                            className="form-label text-black mb-1"
                                        >
                                            Amount
                                        </label>
                                        <ChatSearchInput
                                            type="text"
                                            value={amount}
                                            className="form-control mt-0 text-black"
                                            placeholder="Enter Amount"
                                            onChange={(e) => {
                                                setAmount(e.target.value);
                                            }}
                                        />

                                        {amount > totalCoinBalance && <p className="flex text-danger italic text-xs">You Don't have enough balance**</p>}
                                    </InputDiv>
                                </Col>
                            </Row>
                            <div className=" flex  gap-4 justify-center items-center">
                                <FormButton
                                    className="flex mt-4 w-100"
                                    variant="outline-primary"
                                    // type="submit"
                                    disabled={!fromUserId || !toUserId || !amount || amount > totalCoinBalance}
                                    onClick={handleConfirm}>
                                    Confirm

                                </FormButton>

                                <FormButton
                                    className="flex mt-4 w-100"
                                    variant="outline-danger"
                                    // type="submit"
                                    onClick={hide}>
                                    No

                                </FormButton>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default TransferInternalBalanceModal;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
`;