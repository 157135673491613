import ChatCard from "../Chat/components/ChatCard";
// import { Modal } from 'react-bootstrap';
import { Modal } from "@mui/material";
import ChatField from "../Chat/components/ChatField";
import styled from "styled-components";
import { colorBubble } from "../../../utils/palette";
import { getNotificationToUsers, sendNotificationToUsers } from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { useEffect, useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import moment from "moment";
import { hi } from "date-fns/locale";

const NotifyAllUsers = ({ show, hide, reFetch, message }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState('notification');
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const sendMessage = async (message) => {
        setIsLoading(true)

        try {
            const payload = {
                message: message,
                type: value
            }
            const res = await sendNotificationToUsers(payload)
            reFetch()
            hide()
            setIsLoading(false)

        } catch (error) {

            setIsLoading(false)

        }


    }
    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 99999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>

            <Modal
                open={show}
                onClose={hide}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className="fixed w-full flex items-center justify-center"
            >
                <div className="flex text-white flex-col p-4 bg-[#232329] rounded-lg w-full md:w-1/2">





                    <p className="mb-4 text-lg text-white font-semibold text-black">
                        Send Message to all users
                    </p>
                    <FormControl>
                        <FormLabel className="text-white" id="demo-row-radio-buttons-group-label ">Chose Type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="notification" control={<Radio sx={{
                                color: 'white'
                            }} />} label="Notification" />
                            <FormControlLabel value="email" control={<Radio sx={{
                                color: 'white'
                            }} />} label="Email" />
                            <FormControlLabel value="both" control={<Radio sx={{
                                color: 'white'
                            }} />} label="Both" />
                        </RadioGroup>
                    </FormControl>

                    <hr className="flex py-3" />
                    <div className="flex flex-col max-h-[200px] gap-2 overflow-scroll">
                        {
                            message?.map((item) => {
                                return (

                                    <ChatBubbleMessageWrap>
                                        <p className="text-white">
                                            {item?.message}
                                        </p>
                                        <p className="text-white text-[10px] flex justify-end">
                                            {moment(item?.createdAt).format('lll')}
                                        </p>
                                    </ChatBubbleMessageWrap>
                                )
                            })
                        }


                    </div>


                    <div className="flex  mt-3 justify-end">
                        <ChatField onSendMessage={sendMessage} />
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default NotifyAllUsers;
const ChatBubbleMessageWrap = styled.div`
  max-width: 270px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0 40px 40px 0;
  padding-right: 20px;
  padding-left: 40px;
  background-color: ${colorBubble};
  ${(props) => props.file && "padding: 10px 20px 20px 20px"};

  img {
    max-width: 215px;
  }
`;